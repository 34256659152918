import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './BeastBattle.css';

interface Beast {
  id: number;
  name: string;
  description: string;
  level: number;
  loot: Array<string>;
  health: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  luck: number;
  silver: number;
  experience: number;
}

interface LocationState {
  beast: Beast;
  gladiatorId: number;
}

const BeastBattle: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { beast, gladiatorId } = location.state as LocationState;

  const [surrenderAt, setSurrenderAt] = useState<number>(() => {
    const savedSurrenderValue = localStorage.getItem('surrenderAt');
    return savedSurrenderValue ? parseInt(savedSurrenderValue) : 50;
  });

  const [strategy, setStrategy] = useState<string>(() => {
    const savedStrategy = localStorage.getItem('battleStrategy');
    return savedStrategy || 'Normal';
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        '/api/battle/battle',
        {
          teamOne: [
            {
              gladiatorId: gladiatorId,
              strategy: strategy,
              surrenderHP: surrenderAt / 100,
            },
          ],
          teamTwo: [
            {
              beastId: beast.id,
              strategy: 'Normal',
              surrenderHP: 0,
              type: 'beast',
            },
          ],
          deathMatch: false,
          playerGladiatorId: gladiatorId,
          title: `Beast Battle - ${beast.name}`,
          battleType: 'Beast Battle',
        },
        { withCredentials: true }
      );

      navigate(`/battle-result/${response.data.battleId}`);
    } catch (err: any) {
      console.error('Error initiating battle:', err);
      // Check if there's a response from the server with an error message
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('Failed to initiate battle. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('surrenderAt', surrenderAt.toString());
  }, [surrenderAt]);

  useEffect(() => {
    localStorage.setItem('battleStrategy', strategy);
  }, [strategy]);

  return (
    <div className="beast-battle-container">
      <h2>Prepare for Battle</h2>
      <div className="beast-details">
        <h3>Beast Details</h3>
        <p><strong>Name:</strong> {beast.name}</p>
        <p><strong>Description:</strong> {beast.description}</p>
        <p><strong>Level:</strong> {beast.level}</p>
        <p><strong>Loot:</strong> {beast.loot.join(', ')}</p>
      </div>

      <form onSubmit={handleSubmit} className="battle-preparation-form">
        <div className="form-group">
          <label htmlFor="surrenderAt">Surrender at HP %:</label>
          <select
            id="surrenderAt"
            value={surrenderAt}
            onChange={(e) => setSurrenderAt(parseInt(e.target.value))}
            required
          >
            {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map((value) => (
              <option key={value} value={value}>
                {value}%
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="strategy">Battle Strategy:</label>
          <select
            id="strategy"
            value={strategy}
            onChange={(e) => setStrategy(e.target.value)}
            required
          >
            <option value="Normal">Normal</option>
            <option value="Normal: Light">Normal: Light</option>
            <option value="Normal: Heavy">Normal: Heavy</option>
            <option value="Offensive">Offensive</option>
            <option value="Offensive: Light">Offensive: Light</option>
            <option value="Offensive: Heavy">Offensive: Heavy</option>
            <option value="Defensive">Defensive</option>
            <option value="Defensive: Light">Defensive: Light</option>
            <option value="Defensive: Heavy">Defensive: Heavy</option>
            <option value="Berserk">Berserk</option>
            <option value="Berserk: Light">Berserk: Light</option>
            <option value="Berserk: Heavy">Berserk: Heavy</option>
          </select>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="battle-button" disabled={isLoading}>
          {isLoading ? 'Preparing...' : 'Battle'}
        </button>
      </form>
    </div>
  );
};

export default BeastBattle;
