import React from 'react';

const FriendsList: React.FC = () => {
  return (
    <div>
      <h1>Friends List</h1>
      <p>This is the Friends List component.</p>
    </div>
  );
};

export default FriendsList;
