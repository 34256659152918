// src/components/Auth/Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useGladiator } from '../../contexts/GladiatorContext';
import { useAuth } from '../../contexts/AuthContext';
import { useSocket } from '../../contexts/SocketContext';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setGladiatorId, setGladiator } = useGladiator();
  const { setUser } = useAuth();
  const { socket, setSocket } = useSocket();
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://njordheim.com/api/auth/login', { email, password }, { withCredentials: true });
      const user = response.data;
      setUser(user);

      // Fetch the user's gladiator after login
      const gladiatorResponse = await axios.get(`/api/gladiator`, { withCredentials: true });
      const gladiator = gladiatorResponse.data;

      if (gladiator) {
        setGladiatorId(gladiator.id);
        setGladiator(gladiator);
        if (socket) {
          socket.emit('join_room', user.id);
        }
        navigate('/home');
      } else {
        navigate('/create-gladiator');
      }
    } catch (err: any) {
      setError('Invalid email or password');
      console.error(err);
    }
  };

  const handleDiscordLogin = () => {
    window.location.href = 'https://njordheim.com/api/auth/discord';
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        {error && <p>{error}</p>}
        <button type="submit">Login</button>
      </form>
      <button onClick={handleDiscordLogin}>Login with Discord</button>
    </div>
  );
};

export default Login;
