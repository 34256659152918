const categories = {
    Weapon: ['Axe', 'Mace', 'Sword', 'Staff', 'Dagger', 'Chain'],
    Shield: [],
    Armor: ['Head', 'Shoulder', 'Torso', 'Hand', 'Leg', 'Feet'],
    Accessory: ['Amulet', 'Armring', 'Cloak', 'Neck', 'Ring'],
    Potion: [],
    Material: []
} as const;

export default categories;
