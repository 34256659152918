import React, { useState } from 'react';
import './CategorySelection.css';
import categories from '../../types/categories';

type CategorySelectionProps = {
    onCategorySelect: (category: keyof typeof categories | null, subcategory?: string) => void;
    onSearchChange: (searchTerm: string) => void;
};

const CategorySelection: React.FC<CategorySelectionProps> = ({ onCategorySelect, onSearchChange }) => {
    const [selectedCategory, setSelectedCategory] = useState<keyof typeof categories | null>(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);

    const handleCategoryClick = (category: keyof typeof categories) => {
        const newCategory = selectedCategory === category ? null : category;
        setSelectedCategory(newCategory);
        setSelectedSubcategory(null);
        onCategorySelect(newCategory);
    };

    const handleSubcategoryClick = (category: keyof typeof categories, subcategory: string) => {
        setSelectedCategory(category);
        setSelectedSubcategory(subcategory);
        onCategorySelect(category, subcategory);
    };

    const renderSubcategories = (category: keyof typeof categories) => {
        let subcategoryList: string[] = [];
        
        if (category === "Weapon") subcategoryList = ["Axe", "Sword", "Mace", "Dagger", "Staff", "Chain"];
        else if (category === "Armor") subcategoryList = ["Head", "Shoulder", "Torso", "Hand", "Leg", "Feet"];
        else if (category === "Accessory") subcategoryList = ["Amulet", "Armring", "Cloak", "Neck", "Ring"];
        else subcategoryList = categories[category] as unknown as string[];
    
        return subcategoryList.map((sub) => (
            <button
                key={sub}
                className={`subcategory-button ${selectedSubcategory === sub ? 'active' : ''}`}
                onClick={() => handleSubcategoryClick(category, sub)}
            >
                {sub}
            </button>
        ));
    };    

    return (
        <div className="category-selection">
            <input
                type="text"
                placeholder="Search items..."
                onChange={(e) => onSearchChange(e.target.value)}
                className="search-bar"
            />
            {Object.keys(categories).map((category) => (
                <div key={category} className="category-group">
                    <button
                        className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                        onClick={() => handleCategoryClick(category as keyof typeof categories)}
                    >
                        {category}
                    </button>
                    <div className="subcategories">
                        {renderSubcategories(category as keyof typeof categories)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CategorySelection;
