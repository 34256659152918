import React from 'react';
import axios from 'axios';
import { useGladiator } from '../../contexts/GladiatorContext';
import { useNavigate, Link } from 'react-router-dom';
import { useSocket } from '../../contexts/SocketContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faDragon, faHandRock, faUserGroup, faTrophy, faMedal, faMap, faScaleUnbalancedFlip, faBeer  } from '@fortawesome/free-solid-svg-icons';
import './LeftNavBar.css';

const LeftNavBar: React.FC = () => {
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { gladiator, setGladiator } = useGladiator();

  const handleLogout = async () => {
    try {
      await axios.get('https://njordheim.com/api/auth/logout', { withCredentials: true });

      if (socket) {
        socket.emit('logout');
        socket.disconnect();
      }

      setGladiator(null);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="left-menu">
      <nav className="nav-links">
        <Link to="/market">
          <FontAwesomeIcon icon={faStore} className="icon" />
          Market
        </Link>
        <Link to="/beasts">
          <FontAwesomeIcon icon={faDragon} className="icon" />
          Beasts
        </Link>
        <Link to="/duel">
          <FontAwesomeIcon icon={faHandRock} className="icon" />
          Duel
        </Link>
        <Link to="/team-battle">
          <FontAwesomeIcon icon={faUserGroup} className="icon" />
          Team Battles
        </Link>
        <Link to="/ranking">
          <FontAwesomeIcon icon={faTrophy} className="icon" />
          Ranking
        </Link>
        {gladiator?.id === 52 && (
        <Link to="/tournament">
          <FontAwesomeIcon icon={faMedal} className="icon" />
          Tournaments
        </Link>
        )}
        {gladiator?.id === 52 && (
          <Link to="/adventures">
            <FontAwesomeIcon icon={faMap} className="icon" /> 
            Adventures
          </Link>
        )}
          <Link to="/auction">
            <FontAwesomeIcon icon={faScaleUnbalancedFlip} className="icon" /> 
            Auction House
          </Link>
          <Link to="/tavern">
            <FontAwesomeIcon icon={faBeer} className="icon" /> 
            Stonebolt's Tavern
          </Link>
      </nav>
      <div className="logout-container">
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
    </div>
  );
};

export default LeftNavBar;
