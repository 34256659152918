import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSocket } from '../../contexts/SocketContext';
import { useAuth } from '../../contexts/AuthContext';
import { useGladiator } from '../../contexts/GladiatorContext';
import axios from 'axios';
import './Home.css';

const Home: React.FC = () => {
  const { socket } = useSocket();
  const { user } = useAuth();
  const { gladiator } = useGladiator();

  useEffect(() => {
    if (socket && gladiator?.id) {
      socket.emit('join_room', user.id);
      axios.get('https://njordheim.com/api/gladiator', { withCredentials: true })
        .catch(error => console.error('Error fetching gladiator data:', error));
    }
  }, [socket, gladiator]);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="home-container">
      <h2>Welcome, {user.username}</h2>
      <div className="game-description">
        <h3>About the Game</h3>
        <p>
          Welcome to Njordheim, a fierce and immersive text-based RPG game where you take on the role of a gladiator. 
          In this world, you will create and train your gladiator, preparing for the ultimate battles in the arena. 
          Only the strongest and most strategic gladiator will be crowned the victor each season. 
          Will you rise to the challenge and claim your place as the champion of Njordheim?
        </p>
        <p>
          Join guilds, train in various ways, and face off against other gladiators to bring fame and glory to your guild. 
          Prove your worth and lead your guild to become the most renowned guild of the season.
        </p>
        {gladiator ? (
          <p>Your gladiator, <strong>{gladiator.name}</strong>, is ready for battle!</p>
        ) : (
          <Link to="/create-gladiator" className="create-gladiator-link">Create Your Gladiator</Link>
        )}
      </div>
    </div>
  );
};

export default Home;
