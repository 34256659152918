import React, { useState } from 'react';
import CategorySelection from './CategorySelection';
import Browse from './Browse';
import BuyOrder from './BuyOrder';
import SellOrder from './SellOrder';
import History from './History';
import ActiveOrders from './ActiveOrders';
import categories from '../../types/categories';
import './AuctionHouse.css';

const AuctionHouse = () => {
    const [activeTab, setActiveTab] = useState('Browse');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<keyof typeof categories | null>(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);

    const handleCategorySelect = (category: keyof typeof categories | null, subcategory?: string) => {
        setSelectedCategory(category);
        setSelectedSubcategory(subcategory || null);
        setSearchTerm(''); // Clear search when selecting a category
    };

    const handleSearchChange = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        setSelectedCategory(null);  // Reset category when searching
        setSelectedSubcategory(null);  // Reset subcategory when searching
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Browse':
                return <Browse category={selectedCategory} subcategory={selectedSubcategory} searchTerm={searchTerm} />;
            case 'Buy Order':
                return <BuyOrder />;
            case 'Sell Order':
                return <SellOrder />;
            case 'History':
                return <History />;
            case 'Active Orders':
                return <ActiveOrders />;
            default:
                return <Browse category={selectedCategory} subcategory={selectedSubcategory} searchTerm={searchTerm} />;
        }
    };

    return (
        <div className="auction-house">
            <div className="tabs">
                {['Browse', 'Buy Order', 'Sell Order', 'Active Orders', 'History'].map((tab) => (
                    <button
                        key={tab}
                        className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="main-content">
                <div className="sidebar">
                    <CategorySelection onCategorySelect={handleCategorySelect} onSearchChange={handleSearchChange} />
                </div>
                <div className="content">
                    {renderTabContent()}
                </div>
            </div>
        </div>
    );
};

export default AuctionHouse;
