import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateTournament.css';

const CreateTournament: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    type: 'normal',
    team_size: 1,
    num_teams: 8,
    silver_reward: 0,
    top_3_rewards: '{"1st": 1000, "2nd": 500, "3rd": 250}',
    item_rewards: '{"1st": "Sword", "2nd": "Shield", "3rd": "Armor"}',
    start_time: '',
    legend: false,
    min_level: 1,
    max_level: 100,
    race_requirement: 'All'
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: e.target instanceof HTMLInputElement && e.target.type === 'checkbox' 
        ? e.target.checked 
        : value
    });
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      min_level: formData.legend ? 20 : 1,
      max_level: formData.legend ? 25 : 100,
      type: formData.legend ? 'death' : prevData.type,
    }));
  }, [formData.legend]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post('/api/tournament/create', formData);
      setSuccessMessage('Tournament created successfully!');
      setFormData({
        name: '',
        type: 'normal',
        team_size: 1,
        num_teams: 8,
        silver_reward: 0,
        top_3_rewards: '{"1st": 1000, "2nd": 500, "3rd": 250}',
        item_rewards: '{"1st": "Sword", "2nd": "Shield", "3rd": "Armor"}',
        start_time: '',
        legend: false,
        min_level: 1,
        max_level: 100,
        race_requirement: 'All'
      });
      setTimeout(() => {
        navigate('/admin/tournaments');
      }, 1500);
    } catch (error) {
      console.error('Error creating tournament:', error);
      setSuccessMessage('Failed to create tournament. Please try again.');
    }
  };

  return (
    <div className="create-tournament-container">
      <h2>Create Tournament</h2>
      <form onSubmit={handleSubmit} className="create-tournament-form">
        <label>
          Tournament Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label>

        <label>
          Type:
          <select name="type" value={formData.type} onChange={handleChange} disabled={formData.legend}>
            <option value="normal">Normal</option>
            <option value="death">Death Match</option>
          </select>
        </label>

        <label>
          Team Size:
          <select name="team_size" value={formData.team_size} onChange={handleChange}>
            <option value={1}>1 vs 1</option>
            <option value={2}>2 vs 2</option>
            <option value={3}>3 vs 3</option>
            <option value={4}>4 vs 4</option>
          </select>
        </label>

        <label>
          Number of Contestants:
          <select name="num_teams" value={formData.num_teams} onChange={handleChange}>
            <option value={8}>8</option>
            <option value={16}>16</option>
            <option value={32}>32</option>
            <option value={64}>64</option>
            <option value={128}>128</option>
            <option value={256}>256</option>
            <option value={512}>512</option>
            <option value={1024}>1024</option>
          </select>
        </label>

        <label>
          Silver Reward:
          <input type="number" name="silver_reward" value={formData.silver_reward} onChange={handleChange} />
        </label>

        <label>
          Top 3 Rewards (JSON):
          <input
            type="text"
            name="top_3_rewards"
            value={formData.top_3_rewards}
            onChange={handleChange}
            placeholder='{"1st": 1000, "2nd": 500, "3rd": 250}'
          />
        </label>

        <label>
          Item Rewards (JSON):
          <input
            type="text"
            name="item_rewards"
            value={formData.item_rewards}
            onChange={handleChange}
            placeholder='{"1st": "Sword", "2nd": "Shield", "3rd": "Armor"}'
          />
        </label>

        <label>
          Start Time:
          <input type="datetime-local" name="start_time" value={formData.start_time} onChange={handleChange} required />
        </label>

        <label>
          Legendary Tournament:
          <input type="checkbox" name="legend" checked={formData.legend} onChange={handleChange} />
        </label>

        <label>
          Minimum Level:
          <input
            type="number"
            name="min_level"
            value={formData.min_level}
            onChange={handleChange}
            min={formData.legend ? 20 : 1}
          />
        </label>

        <label>
          Maximum Level:
          <input
            type="number"
            name="max_level"
            value={formData.max_level}
            onChange={handleChange}
            min={formData.legend ? 20 : 1}
            max={formData.legend ? 25 : 100}
          />
        </label>

        <label>
          Race Requirement:
          <select name="race_requirement" value={formData.race_requirement} onChange={handleChange}>
            <option value="All">All</option>
            <option value="Red Blood">Red Blood (Elf, Dwarf, Human)</option>
            <option value="Black Blood">Black Blood (Orc, Goblin, Troll)</option>
            <option value="Elf">Elf</option>
            <option value="Dwarf">Dwarf</option>
            <option value="Human">Human</option>
            <option value="Orc">Orc</option>
            <option value="Goblin">Goblin</option>
            <option value="Troll">Troll</option>
          </select>
        </label>

        <button type="submit" className="create-tournament-btn">Create Tournament</button>
      </form>

      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default CreateTournament;
