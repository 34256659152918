import React, { useState } from 'react';
import axios from 'axios';

const CreateBeast: React.FC = () => {
  const [beastData, setBeastData] = useState({
    name: '',
    description: '',
    avatar: '',
    level: 1,
    health: 100,
    strength: 10,
    endurance: 10,
    initiative: 10,
    dodge: 10,
    learning: 10,
    luck: 10,
    discipline: 10,
    leadership: 10,
    provocation: 10,
    silver: 100,
    experience: 50,
    loot: '',
    mainHandEquipment: '',
    offHandEquipment: '',
    headEquipment: '',
    bodyEquipment: '',
    legsEquipment: '',
    isBoss: false, // Default is false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      setBeastData(prevState => ({
        ...prevState,
        [name]: (e.target as HTMLInputElement).checked,
      }));
    } else {
      setBeastData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Convert loot string to an array of integers
      const formattedLoot = beastData.loot.split(',').map(item => parseInt(item.trim()));

      // Prepare the payload
      const payload = {
        ...beastData,
        loot: formattedLoot,
      };

      console.log('Payload to be sent:', payload);

      await axios.post('https://njordheim.com/api/admin/beast', payload, { withCredentials: true });
      alert('Beast created successfully!');
    } catch (error) {
      console.error('Error creating beast:', error);
      alert('Failed to create beast.');
    }
  };

  return (
    <div style={{ color: '#f0f0f0' }}>
      <h1>Create Beast</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={beastData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Description:</label>
          <textarea name="description" value={beastData.description} onChange={handleChange} required />
        </div>
        <div>
          <label>Avatar (name):</label>
          <input type="text" name="avatar" value={beastData.avatar} onChange={handleChange} />
        </div>
        <div>
          <label>Level:</label>
          <input type="number" name="level" value={beastData.level} onChange={handleChange} min="1" required />
        </div>
        <div>
          <label>Health:</label>
          <input type="number" name="health" value={beastData.health} onChange={handleChange} required />
        </div>
        <div>
          <label>Strength:</label>
          <input type="number" name="strength" value={beastData.strength} onChange={handleChange} required />
        </div>
        <div>
          <label>Endurance:</label>
          <input type="number" name="endurance" value={beastData.endurance} onChange={handleChange} required />
        </div>
        <div>
          <label>Initiative:</label>
          <input type="number" name="initiative" value={beastData.initiative} onChange={handleChange} required />
        </div>
        <div>
          <label>Dodge:</label>
          <input type="number" name="dodge" value={beastData.dodge} onChange={handleChange} required />
        </div>
        <div>
          <label>Learning:</label>
          <input type="number" name="learning" value={beastData.learning} onChange={handleChange} required />
        </div>
        <div>
          <label>Luck:</label>
          <input type="number" name="luck" value={beastData.luck} onChange={handleChange} required />
        </div>
        <div>
          <label>Discipline:</label>
          <input type="number" name="discipline" value={beastData.discipline} onChange={handleChange} required />
        </div>
        <div>
          <label>Leadership:</label>
          <input type="number" name="leadership" value={beastData.leadership} onChange={handleChange} required />
        </div>
        <div>
          <label>Provocation:</label>
          <input type="number" name="provocation" value={beastData.provocation} onChange={handleChange} required />
        </div>
        <div>
          <label>Silver:</label>
          <input type="number" name="silver" value={beastData.silver} onChange={handleChange} required />
        </div>
        <div>
          <label>Experience:</label>
          <input type="number" name="experience" value={beastData.experience} onChange={handleChange} required />
        </div>
        <div>
          <label>Loot (Item IDs, comma-separated):</label>
          <input type="text" name="loot" value={beastData.loot} onChange={handleChange} />
        </div>
        <div>
          <label>Main Hand Equipment (ID):</label>
          <input type="text" name="mainHandEquipment" value={beastData.mainHandEquipment} onChange={handleChange} />
        </div>
        <div>
          <label>Off Hand Equipment (ID):</label>
          <input type="text" name="offHandEquipment" value={beastData.offHandEquipment} onChange={handleChange} />
        </div>
        <div>
          <label>Head Equipment (ID):</label>
          <input type="text" name="headEquipment" value={beastData.headEquipment} onChange={handleChange} />
        </div>
        <div>
          <label>Body Equipment (ID):</label>
          <input type="text" name="bodyEquipment" value={beastData.bodyEquipment} onChange={handleChange} />
        </div>
        <div>
          <label>Legs Equipment (ID):</label>
          <input type="text" name="legsEquipment" value={beastData.legsEquipment} onChange={handleChange} />
        </div>
        <div>
          <label>Is Boss:</label>
          <input
            type="checkbox"
            name="isBoss"
            checked={beastData.isBoss}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Create Beast</button>
      </form>
    </div>
  );
};

export default CreateBeast;
