import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useGladiator } from '../../contexts/GladiatorContext';
import './CreateGladiator.css';

interface PrimaryPoints {
  health: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  axe: number;
  sword: number;
  mace: number;
  staff: number;
  shield: number;
  dagger: number;
  chain: number;
}

interface SecondaryPoints {
  learning: number;
  luck: number;
  discipline: number;
  leadership: number;
  provocation: number;
}

const initialPrimaryPoints: PrimaryPoints = {
  health: 0,
  strength: 0,
  endurance: 0,
  initiative: 0,
  dodge: 0,
  axe: 0,
  sword: 0,
  mace: 0,
  staff: 0,
  shield: 0,
  dagger: 0,
  chain: 0,
};

const initialSecondaryPoints: SecondaryPoints = {
  learning: 0,
  luck: 0,
  discipline: 0,
  leadership: 0,
  provocation: 0,
};

const CreateGladiator: React.FC = () => {
  const { user } = useAuth();
  const { setGladiatorId } = useGladiator();
  const [name, setName] = useState<string>('');
  const [race, setRace] = useState<string>('Elf');
  const [sex, setSex] = useState<string>('Male');
  const [primaryPoints, setPrimaryPoints] = useState<PrimaryPoints>(initialPrimaryPoints);
  const [secondaryPoints, setSecondaryPoints] = useState<SecondaryPoints>(initialSecondaryPoints);
  const [primaryPointsLeft, setPrimaryPointsLeft] = useState<number>(100);
  const [secondaryPointsLeft, setSecondaryPointsLeft] = useState<number>(5);
  const [avatar, setAvatar] = useState<number | null>(null);
  const [error, setError] = useState<string>('');
  const [isNameAvailable, setIsNameAvailable] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (name) {
        try {
          const response = await axios.get(`/api/gladiator/check-name?name=${name}`);
          setIsNameAvailable(response.data.isAvailable);
        } catch (error) {
          console.error('Error checking name availability:', error);
        }
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [name]);

  const handlePrimaryChange = (skill: keyof PrimaryPoints, value: string) => {
    const parsedValue = value === '' ? 0 : parseInt(value, 10);
    const newPoints = { ...primaryPoints, [skill]: parsedValue };
    const totalPoints = Object.values(newPoints).reduce((a, b) => a + b, 0);
    if (totalPoints <= 100) {
      setPrimaryPoints(newPoints);
      setPrimaryPointsLeft(100 - totalPoints);
    }
  };

  const handleSecondaryChange = (skill: keyof SecondaryPoints, value: string) => {
    const parsedValue = value === '' ? 0 : parseInt(value, 10);
    const newPoints = { ...secondaryPoints, [skill]: parsedValue };
    const totalPoints = Object.values(newPoints).reduce((a, b) => a + b, 0);
    if (totalPoints <= 5) {
      setSecondaryPoints(newPoints);
      setSecondaryPointsLeft(5 - totalPoints);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Ensure all empty fields are treated as 0 for primary and secondary points
    const sanitizedPrimaryPoints = Object.keys(primaryPoints).reduce((acc, key) => {
      acc[key as keyof PrimaryPoints] = primaryPoints[key as keyof PrimaryPoints] || 0;
      return acc;
    }, {} as PrimaryPoints);

    const sanitizedSecondaryPoints = Object.keys(secondaryPoints).reduce((acc, key) => {
      acc[key as keyof SecondaryPoints] = secondaryPoints[key as keyof SecondaryPoints] || 0;
      return acc;
    }, {} as SecondaryPoints);

    // Check if points are fully allocated
    if (primaryPointsLeft !== 0 || secondaryPointsLeft !== 0) {
      setError('You must allocate all points.');
      return;
    }

    // Ensure avatar is selected
    if (avatar === null) {
      setError('You must select an avatar.');
      return;
    }

    // Check if name is available
    if (isNameAvailable === false) {
      setError('The gladiator name is already taken. Please choose another name.');
      return;
    }

    // Send the sanitized data to the backend
    try {
      const response = await axios.post('/api/gladiator', {
        name,
        race,
        sex,
        avatar,
        ...sanitizedPrimaryPoints,
        ...sanitizedSecondaryPoints
      }, { withCredentials: true });

      // Update the gladiator context with the new gladiator ID
      if (response.data.gladiatorId) {
        setGladiatorId(response.data.gladiatorId);
      }

      // Navigate to the gladiator profile page
      navigate('/gladiator');
      
    } catch (err) {
      setError('Error creating gladiator.');
      console.error(err);
    }
  };

  // Generate avatar URLs based on race and sex
  const getAvatarGrid = () => {
    const avatars = [];
    for (let i = 1; i <= 9; i++) {
      avatars.push(
        <div key={i} className={`avatar-item ${avatar === i ? 'selected' : ''}`} onClick={() => setAvatar(i)}>
          <img
            src={`/img/avatars/${race.toLowerCase()}.${sex.toLowerCase()}.${i}.png`}
            alt={`Avatar ${i}`}
          />
        </div>
      );
    }
    return avatars;
  };

  return (
    <div className="create-gladiator-container">
      <h2>Create Gladiator</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            required
          />
          {isNameAvailable === false && <p style={{ color: 'red' }}>This name is already taken.</p>}
        </div>
        <div>
          <label>Race:</label>
          <select value={race} onChange={(e: ChangeEvent<HTMLSelectElement>) => setRace(e.target.value)} required>
            <option value="Elf">Elf</option>
            <option value="Dwarf">Dwarf</option>
            <option value="Goblin">Goblin</option>
            <option value="Human">Human</option>
            <option value="Orc">Orc</option>
            <option value="Troll">Troll</option>
          </select>
        </div>
        <div>
          <label>Sex:</label>
          <select value={sex} onChange={(e: ChangeEvent<HTMLSelectElement>) => setSex(e.target.value)} required>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        {/* Avatar Selection */}
        <div>
          <h3>Select Avatar</h3>
          <div className="avatar-grid">
            {getAvatarGrid()}
          </div>
        </div>

        {/* Primary Skill Points Allocation */}
        <div>
          <h3>Primary Skill Points (100 points total)</h3>
          {Object.keys(initialPrimaryPoints).map((skill) => (
            <div key={skill}>
              <label>{skill.charAt(0).toUpperCase() + skill.slice(1)}:</label>
              <input
                type="number"
                value={primaryPoints[skill as keyof PrimaryPoints] === 0 ? '' : primaryPoints[skill as keyof PrimaryPoints]}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handlePrimaryChange(skill as keyof PrimaryPoints, e.target.value)}
                min="0"
                max="100"
              />
            </div>
          ))}
          <p>Points left: {primaryPointsLeft}</p>
        </div>

        {/* Secondary Skill Points Allocation */}
        <div>
          <h3>Secondary Skill Points (5 points total)</h3>
          {Object.keys(initialSecondaryPoints).map((skill) => (
            <div key={skill}>
              <label>{skill.charAt(0).toUpperCase() + skill.slice(1)}:</label>
              <input
                type="number"
                value={secondaryPoints[skill as keyof SecondaryPoints] === 0 ? '' : secondaryPoints[skill as keyof SecondaryPoints]}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSecondaryChange(skill as keyof SecondaryPoints, e.target.value)}
                min="0"
                max="5"
              />
            </div>
          ))}
          <p>Points left: {secondaryPointsLeft}</p>
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button type="submit">Create Gladiator</button>
      </form>
    </div>
  );
};

export default CreateGladiator;
