import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './DeathScreen.css';

const DeathScreen: React.FC = () => {
  const { battleReportId } = useParams<{ battleReportId: string }>();
  const [battleReport, setBattleReport] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBattleReport = async () => {
      try {
        const response = await axios.get(`/api/battle/result/${battleReportId}`, { withCredentials: true });
        setBattleReport(response.data);
      } catch (error) {
        console.error('Error fetching battle report:', error);
      }
    };

    fetchBattleReport();
  }, [battleReportId]);

  const handleCreateNewGladiator = async () => {
    try {
      await axios.post('/api/user/reset-death-status', { withCredentials: true });
      navigate('/create-gladiator');
    } catch (error) {
      console.error('Error updating user death status:', error);
    }
  };

  const parseLogWithColors = (log: string) => {
    return log.split(/(\{\{.*?\}\})/).map((segment, i) => {
      const match = segment.match(/\{\{(.*?)\}\}/);
      if (match) {
        const content = match[1];
        const parts = content.split(':');

        if (parts.length === 3) {
          const [type, id, name] = parts;
          return <span key={i} style={{ color: type === 'teamOne' ? '#1E90FF' : '#ca2626' }}>{name}</span>;
        }

        return <span key={i} style={{ color: '#ca2626' }}>{parts[parts.length - 1]}</span>;
      }
      return <span key={i}>{segment}</span>;
    });
  };

  const renderSummaryTable = (teamType: string, teamSummary: any, participantSummaries: any) => (
    <div style={{ width: '48%' }}>
      <h4>{teamType === 'teamOne' ? 'Team One' : 'Team Two'}</h4>
      <table style={{ width: '100%', marginBottom: '20px', textAlign: 'left', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Total Damage</th>
            <th>Largest Hit</th>
            <th>Damage Taken</th>
            <th>Attacks Made</th>
            <th>Dodges</th>
            <th>Parries</th>
            <th>Blocks</th>
            <th>Missed</th>
            <th>Critical</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(participantSummaries).map((participantKey) => {
            const participant = participantSummaries[participantKey];
            if (participant.team === teamType) {
              return (
                <tr key={participantKey}>
                  <td>{participant.name}</td>
                  <td>{participant.damage}</td>
                  <td>{participant.highestDamageDealt}</td>
                  <td>{participant.damageTaken}</td>
                  <td>{participant.attacksMade}</td>
                  <td>{participant.dodgedAttacks}</td>
                  <td>{participant.parries}</td>
                  <td>{participant.blockedAttacks}</td>
                  <td>{participant.missedAttacks}</td>
                  <td>{participant.criticalHits}</td>
                </tr>
              );
            }
            return null;
          })}
          <tr>
            <td><strong>Team Total</strong></td>
            <td><strong>{teamSummary.totalDamage}</strong></td>
            <td><strong>{teamSummary.highestDamageDealt}</strong></td>
            <td><strong>{teamSummary.damageTaken}</strong></td>
            <td><strong>{teamSummary.attacksMade}</strong></td>
            <td><strong>{teamSummary.dodgedAttacks}</strong></td>
            <td><strong>{teamSummary.parries}</strong></td>
            <td><strong>{teamSummary.blockedAttacks}</strong></td>
            <td><strong>{teamSummary.missedAttacks}</strong></td>
            <td><strong>{teamSummary.criticalHits}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  if (!battleReport) {
    return <div>Loading battle report...</div>;
  }

  return (
    <div className="deathscreen-container">
      <h1>Your Gladiator Has Fallen</h1>
      <div>
        {battleReport.reportData.combatLog.map((log: string, index: number) => (
          <p key={index}>{parseLogWithColors(log)}</p>
        ))}
        <h3>{battleReport.reportData.winner === 'teamOne' ? 'Team One Wins!' : 'Team Two Wins!'}</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {renderSummaryTable('teamOne', battleReport.reportData.teamSummaries.teamOne, battleReport.reportData.participantSummaries)}
          {renderSummaryTable('teamTwo', battleReport.reportData.teamSummaries.teamTwo, battleReport.reportData.participantSummaries)}
        </div>
      </div>

      {/* Create New Gladiator Button */}
      <button className="create-new-button" onClick={handleCreateNewGladiator}>
        Create New Gladiator
      </button>
    </div>
  );
};

export default DeathScreen;
