import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGladiator } from '../../contexts/GladiatorContext';
import { Link } from 'react-router-dom';
import './BattleHistory.css';

interface BattleReport {
  id: number;
  title: string;
  createdAt: string;
  winningGladiatorIds: string | null;
  losingGladiatorIds: string | null;
}

const BattleHistory: React.FC = () => {
  const { gladiator } = useGladiator();
  const [battles, setBattles] = useState<BattleReport[]>([]);
  const [page, setPage] = useState(1);
  const [totalBattles, setTotalBattles] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBattleHistory = async () => {
      try {
        if (gladiator) {
          const response = await axios.get(`/api/battle/history/${gladiator.id}?page=${page}&limit=20`);
          setBattles(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching battle history:', error);
        setLoading(false);
      }
    };

    fetchBattleHistory();
  }, [gladiator, page]);

  const handleNextPage = () => {
    if (battles.length === 20) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const renderWinLoss = (battle: BattleReport) => {
    if (battle.winningGladiatorIds && battle.winningGladiatorIds.includes(`${gladiator?.id}`)) {
      return <span className="status-win">Win</span>;
    } else if (battle.losingGladiatorIds && battle.losingGladiatorIds.includes(`${gladiator?.id}`)) {
      return <span className="status-loss">Loss</span>;
    } else {
      return <span className="status-unknown">N/A</span>;
    }
  };

  return (
    <div className="battle-history-container">
      <h1>Battle History</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className="battle-history-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
                <th>Result</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {battles.map((battle) => (
                <tr key={battle.id}>
                  <td>{new Date(battle.createdAt).toLocaleDateString()}</td>
                  <td>{battle.title}</td>
                  <td>{renderWinLoss(battle)}</td>
                  <td>
                    <Link to={`/battle-result/${battle.id}`} className="view-button">
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={page === 1}>
              Previous
            </button>
            <button onClick={handleNextPage} disabled={battles.length < 20}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BattleHistory;
