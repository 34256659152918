import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useGladiator } from '../../contexts/GladiatorContext';
import './Beasts.css';

interface Beast {
  id: number;
  name: string;
  level: number;
  loot: string[];
  health: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  luck: number;
  silver: number;
  experience: number;
  isBoss: boolean;
  minRequiredLevel: number;
  maxRequiredLevel: number;
}

interface Gladiator {
  id: number;
  name: string;
  level: number;
}

const Beasts: React.FC = () => {
  const [beasts, setBeasts] = useState<Beast[]>([]);
  const [gladiator, setGladiator] = useState<Gladiator | null>(null);
  const [filterByLevel, setFilterByLevel] = useState<boolean>(() => {
    const savedFilter = localStorage.getItem('filterByLevel');
    return savedFilter ? JSON.parse(savedFilter) : false;
  });

  const navigate = useNavigate();
  const { gladiatorId } = useGladiator();

  useEffect(() => {
    const fetchGladiator = async () => {
      if (gladiatorId) {
        try {
          const response = await axios.get(`/api/gladiator/`, { withCredentials: true });
          setGladiator(response.data);
        } catch (error) {
          console.error('Error fetching gladiator details:', error);
        }
      }
    };

    fetchGladiator();
  }, [gladiatorId]);

  useEffect(() => {
    const fetchBeasts = async () => {
      try {
        const response = await axios.get('/api/beast/', { withCredentials: true });
        setBeasts(response.data);
      } catch (error) {
        console.error('Error fetching beasts:', error);
      }
    };

    fetchBeasts();
  }, []);

  useEffect(() => {
    localStorage.setItem('filterByLevel', JSON.stringify(filterByLevel));
  }, [filterByLevel]);

  const handlePrepareBattle = (beast: Beast) => {
    if (gladiator) {
      navigate('/beast-battle', { state: { beast, gladiatorId: gladiator.id } });
    }
  };

  const filteredBeasts = filterByLevel
  ? beasts.filter(
      (beast) =>
        gladiator !== null &&
        gladiator.level !== undefined &&
        gladiator.level >= beast.minRequiredLevel &&
        gladiator.level <= beast.maxRequiredLevel &&
        !beast.isBoss
    )
  : beasts;

  return (
    <div className="beasts-container">
      <h2>Beasts</h2>
      <p>Here you can see the beasts you can pick a training battle with in the arena.</p>
      <div className="filter-container">
        <label>
          <input
            type="checkbox"
            checked={filterByLevel}
            onChange={(e) => setFilterByLevel(e.target.checked)}
          />
          Show only beasts that match my level
        </label>
      </div>

      {filteredBeasts.length > 0 ? (
        <table className="beasts-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Rewards</th>
              <th>Loot</th>
              <th>Level</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredBeasts.map((beast) => (
              <tr key={beast.id}>
                <td>{beast.name}</td>
                <td>{beast.silver} silver & {beast.experience} EXP</td>
                <td>{beast.loot.join(', ')}</td>
                <td>{beast.minRequiredLevel} - {beast.maxRequiredLevel}</td>
                <td>
                  <button onClick={() => handlePrepareBattle(beast)}>Prepare Battle</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No beasts available for the selected filter.</p>
      )}
    </div>
  );
};

export default Beasts;
