import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './AdminPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faUsers, faClipboardList, faPlus, faDiceD20, faFlask, faNewspaper } from '@fortawesome/free-solid-svg-icons';

const AdminPage: React.FC = () => {
  const [mdFiles, setMdFiles] = useState<string[]>([]);

  useEffect(() => {
    const fetchMdFiles = async () => {
      try {
        const response = await axios.get('https://njordheim.com/api/admin/md-files', { withCredentials: true });
        setMdFiles(response.data);
      } catch (error) {
        console.error('Failed to fetch Markdown files:', error);
      }
    };

    fetchMdFiles();
  }, []);

  return (
    <div className="admin-container">
      <aside className="admin-sidebar">
        <h1>Admin Actions</h1>
        <ul className="admin-menu-list">
          <li><Link to="/post-patch-note"><FontAwesomeIcon icon={faNewspaper} /> Post Patch Note</Link></li>
          <li><Link to="/create-beast"><FontAwesomeIcon icon={faDiceD20} /> Create Beast</Link></li>
          <li><Link to="/create-item"><FontAwesomeIcon icon={faFlask} /> Create Item</Link></li>
          <li><Link to="/assign-items"><FontAwesomeIcon icon={faClipboardList} /> Assign Items to Vendors</Link></li>
          <li><Link to="/battle-test"><FontAwesomeIcon icon={faUsers} /> Battle Test</Link></li>
          <li><Link to="/create-tournament"><FontAwesomeIcon icon={faPlus} /> Create Tournament</Link></li>
        </ul>

        <div className="admin-usage-section">
          <h3>Disk Space Used</h3>
          <div className="admin-progress-bar">
            <div className="admin-progress" style={{ width: '60%' }}></div>
          </div>
          <h3>Bandwidth Used</h3>
          <div className="admin-progress-bar">
            <div className="admin-progress" style={{ width: '40%' }}></div>
          </div>
        </div>
      </aside>

      <main className="admin-main-content">
        <div className="admin-header">
          <h2>Markdown Files</h2>
          <div className="admin-md-dropdown-container">
            <button className="admin-md-btn">Select Markdown File</button>
            <div className="admin-md-content">
              <ul className="admin-file-list">
                {mdFiles.map((filename) => (
                  <li key={filename}>
                    <Link to={`/docs/${filename}`} className="admin-link">
                      {filename}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <section className="admin-placeholder-section">
          <h2>Admin Content Area</h2>
        </section>
      </main>
    </div>
  );
};

export default AdminPage;
