import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useGladiator } from '../../contexts/GladiatorContext';
import Tooltip from './ItemTooltip';
import { Item } from '../types/Item';
import './Market.css';

interface Vendor {
  id: number;
  name: string;
  description: string;
}

interface VendorInventory {
  id: number;
  vendorId: number;
  itemId: number;
  stock: number;
  price: number;
  itemCategory: string;
  itemSubCategory: string;
  img?: string;
  itemDetails: Item;
}

const Marketplace: React.FC = () => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>('Weapon');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
  const [items, setItems] = useState<VendorInventory[]>([]);
  const [loadingVendors, setLoadingVendors] = useState<boolean>(true);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const { gladiatorId } = useGladiator();

  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors = () => {
    setLoadingVendors(true);
    axios
      .get('/api/market/vendors', { withCredentials: true })
      .then((response) => {
        setVendors(response.data);
        setLoadingVendors(false);
      })
      .catch((error) => {
        console.error('Error fetching vendors:', error);
        setLoadingVendors(false);
      });
  };

  const handleVendorSelect = (vendor: Vendor) => {
    setSelectedVendor(vendor);
    setSelectedTab('Weapon');
    setSelectedSubCategory(null);
    fetchVendorItems(vendor.id);
  };

  const fetchVendorItems = (vendorId: number) => {
    setLoadingItems(true);
    axios
      .get(`/api/market/vendors/${vendorId}/items`, { withCredentials: true })
      .then((response) => {
        setItems(response.data);
        setLoadingItems(false);
      })
      .catch((error) => {
        console.error('Error fetching vendor items:', error);
        setLoadingItems(false);
      });
  };

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
    setSelectedSubCategory(null);
  };

  const handleSubCategorySelect = (subCategory: string) => {
    setSelectedSubCategory(subCategory);
  };

  const handleBuyItem = (item: VendorInventory) => {
    axios
      .post(
        '/api/market/buy',
        {
          gladiatorId: gladiatorId,
          vendorId: selectedVendor?.id,
          itemId: item.itemId,
          itemType: item.itemCategory,
        },
        { withCredentials: true }
      )
      .then((response) => {
        alert('Item bought successfully!');
        fetchVendorItems(selectedVendor!.id);
      })
      .catch((error) => {
        console.error('Error buying item:', error);
        alert('Failed to buy item.');
      });
  };

  const filteredItems = items
  .filter((item) => {
    const isCategoryMatch = item.itemCategory === selectedTab;
    const isSubCategoryMatch =
      !selectedSubCategory ||
      (selectedTab === 'Weapon' && item.itemDetails.weaponType === selectedSubCategory) ||
      (selectedTab === 'Armor' && item.itemDetails.armorType === selectedSubCategory) ||
      (selectedTab === 'Accessory' && item.itemDetails.accessoryType === selectedSubCategory);

    return isCategoryMatch && isSubCategoryMatch;
  })
  .sort((a, b) => {
    const levelA = a.itemDetails.requiredLevel || 0;
    const levelB = b.itemDetails.requiredLevel || 0;

    // Sort primarily by level, and then by name if levels are equal
    if (levelA !== levelB) {
      return levelA - levelB;
    }
    return a.itemDetails.name.localeCompare(b.itemDetails.name);
  });

  const renderSubCategoryButtons = () => {
    const subCategories: Record<string, string[]> = {
      Weapon: ['Axe', 'Mace', 'Sword', 'Staff', 'Dagger', 'Chain'],
      Armor: ['Head', 'Shoulders', 'Torso', 'Hands', 'Legs', 'Feet'],
      Accessory: ['Cloak', 'Neck', 'Ring', 'Armring', 'Amulet'],
    };

    if (subCategories[selectedTab]) {
      return (
        <div className="sub-category-buttons">
          {subCategories[selectedTab].map((subCategory) => (
            <button
              key={subCategory}
              onClick={() => handleSubCategorySelect(subCategory)}
              style={getTabButtonStyle(selectedSubCategory === subCategory)}
            >
              {subCategory}
            </button>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderTableHeaders = () => {
    switch (selectedTab) {
      case 'Weapon':
        return (
          <>
            <th>Name</th>
            <th>Damage</th>
            <th>Slot</th>
            <th>Level Req.</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Armor':
        return (
          <>
            <th>Name</th>
            <th>Absorption</th>
            <th>Armor Type</th>
            <th>Level Req.</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Shield':
        return (
          <>
            <th>Name</th>
            <th>Block Value</th>
            <th>Level Req.</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Potion':
        return (
          <>
            <th>Name</th>
            <th>Effect</th>
            <th>Duration</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      default:
        return null;
    }
  };

  const renderTableRows = () => {
    return filteredItems.map((item) => (
      <tr key={item.id}>
        <td>
          <Tooltip item={item.itemDetails}>
            <span>{item.itemDetails.name}</span>
          </Tooltip>
        </td>
        {selectedTab === 'Weapon' && (
          <>
            <td>{item.itemDetails.minDamage} - {item.itemDetails.maxDamage}</td>
            <td>{item.itemDetails.slot === 'oneHand' ? 'One-Handed' : 'Two-Handed'}</td>
            <td>{item.itemDetails.requiredLevel}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className="buy-button">Buy</button></td>
          </>
        )}
        {selectedTab === 'Armor' && (
          <>
            <td>{item.itemDetails.absorption}</td>
            <td>{item.itemDetails.armorType}</td>
            <td>{item.itemDetails.requiredLevel}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className="buy-button">Buy</button></td>
          </>
        )}
        {selectedTab === 'Shield' && (
          <>
            <td>{item.itemDetails.blockValue}</td>
            <td>{item.itemDetails.requiredLevel}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className="buy-button">Buy</button></td>
          </>
        )}
        {selectedTab === 'Potion' && (
          <>
            <td>{item.itemDetails.potionEffect}</td>
            <td>{item.itemDetails.duration} seconds</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className="buy-button">Buy</button></td>
          </>
        )}
      </tr>
    ));
  };

  if (loadingVendors) {
    return <div className="loading">Loading vendors...</div>;
  }

  if (!selectedVendor) {
    return (
      <div className="market-container">
        <h1 className="market-title">Marketplace</h1>
        <p className="vendor-selection">Choose a merchant to view their goods:</p>
        <div className="vendor-list">
          {vendors.map((vendor) => (
            <div key={vendor.id} onClick={() => handleVendorSelect(vendor)} className="vendor-card">
              <h3 className="vendor-name">{vendor.name}</h3>
              <p className="vendor-description">{vendor.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="market-container">
      <h1 className="market-title">{selectedVendor.name}</h1>
      <button onClick={() => setSelectedVendor(null)} className="back-button">
        Back to Marketplace
      </button>

      <div className="items-section">
        <div className="tabs">
          <button onClick={() => handleTabSelect('Weapon')} style={getTabButtonStyle(selectedTab === 'Weapon')}>
            Weapons
          </button>
          <button onClick={() => handleTabSelect('Armor')} style={getTabButtonStyle(selectedTab === 'Armor')}>
            Armor
          </button>
          <button onClick={() => handleTabSelect('Shield')} style={getTabButtonStyle(selectedTab === 'Shield')}>
            Shields
          </button>
          <button onClick={() => handleTabSelect('Accessory')} style={getTabButtonStyle(selectedTab === 'Accessory')}>
            Accessories
          </button>
          <button onClick={() => handleTabSelect('Potion')} style={getTabButtonStyle(selectedTab === 'Potion')}>
            Potions
          </button>
        </div>

        {renderSubCategoryButtons()}

        {loadingItems ? (
          <div className="loading">Loading items...</div>
        ) : filteredItems.length > 0 ? (
          <div className="items-table-container">
            <table className="items-table">
              <thead>
                <tr>{renderTableHeaders()}</tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        ) : (
          <p className="no-items-message">
            No items available for {selectedTab} {selectedSubCategory && ` - ${selectedSubCategory}`}
          </p>
        )}
      </div>
    </div>
  );
};

// Helper function to style tab buttons
const getTabButtonStyle = (isActive: boolean) => ({
  backgroundColor: isActive ? '#00bfff' : '#2a2a2a',
  color: isActive ? '#2a2a2a' : '#00bfff',
  border: 'none',
  borderRadius: '5px',
  padding: '10px 15px',
  cursor: 'pointer',
  marginRight: '10px',
});

export default Marketplace;
