import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import './PostPatchNotes.css';

const PostPatchNotes: React.FC = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [version, setVersion] = useState('');
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState('');
  const { user } = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/admin/add-patch-note', {
        title,
        content,
        version,
        date,
        author: user.username,
        authorId: user.id,
      });
      setStatus('Patch note posted successfully');
    } catch (error) {
      console.error('Failed to post patch note:', error);
      setStatus('Failed to post patch note');
    }
  };

  return (
    <div className="post-patch-note">
      <h2>Post Patch Notes</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Version"
          value={version}
          onChange={(e) => setVersion(e.target.value)}
          required
        />
        <textarea
          placeholder="Patch Note Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        ></textarea>
        <button type="submit">Post Patch Note</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

export default PostPatchNotes;
