import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './Lobby.css';

interface Gladiator {
  id: number;
  name: string;
  level: number;
}

interface Lobby {
  id: number;
  name: string;
  minLevel: number;
  maxLevel: number;
  maxParticipants: number;
  randomTeams: boolean;
  currentParticipants: number;
  teamOne: Gladiator[];
  teamTwo: Gladiator[];
}

const Lobby: React.FC = () => {
  const { lobbyId } = useParams<{ lobbyId: string }>();
  const [lobby, setLobby] = useState<Lobby | null>(null);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const fetchLobbyDetails = async () => {
    try {
      const response = await axios.get(`/api/team-battle/lobbies/${lobbyId}`);
      setLobby(response.data);
    } catch (error) {
      console.error('Error fetching lobby details:', error);
      setError('Failed to fetch lobby details.');
    }
  };

  useEffect(() => {
    fetchLobbyDetails();
  }, [lobbyId]);

  const handleJoinTeam = async (team: 'teamOne' | 'teamTwo') => {
    try {
      await axios.post(`/api/team-battle/lobbies/${lobbyId}/join`, { team }, { withCredentials: true });
      fetchLobbyDetails();
    } catch (error) {
      console.error('Error joining team:', error);
      setError('Failed to join the team.');
    }
  };

  if (!lobby) {
    return <div>Loading...</div>;
  }

  return (
    <div className="lobby-container">
      <h1 className="lobby-title">{lobby.name}</h1>
      <div className="lobby-info">
        <p>Level Range: {lobby.minLevel} - {lobby.maxLevel}</p>
        <p>Max Participants: {lobby.maxParticipants}</p>
        <p>Random Teams: {lobby.randomTeams ? 'Yes' : 'No'}</p>
      </div>
      <div className="teams">
        <div className="team">
          <h3>Team 1</h3>
          <ul>
            {lobby.teamOne.map((gladiator) => (
              <li key={gladiator.id}>{gladiator.name} (Level {gladiator.level})</li>
            ))}
          </ul>
          <button className="join-button" onClick={() => handleJoinTeam('teamOne')}>
            Join Team 1
          </button>
        </div>
        <div className="team">
          <h3>Team 2</h3>
          <ul>
            {lobby.teamTwo.map((gladiator) => (
              <li key={gladiator.id}>{gladiator.name} (Level {gladiator.level})</li>
            ))}
          </ul>
          <button className="join-button" onClick={() => handleJoinTeam('teamTwo')}>
            Join Team 2
          </button>
        </div>
      </div>
      {error && <p className="error">{error}</p>}
      <button className="back-button" onClick={() => navigate('/team-battle')}>
        Back to Lobbies
      </button>
    </div>
  );
};

export default Lobby;
