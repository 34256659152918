// src/components/Shared/Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

interface User {
  role: {
    name: string;
  };
}

interface HeaderProps {
  user: User | null;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
  return (
    <header className="banner">
      <Link to="/home">
        <h1>Njordheim Arena</h1>
      </Link>
      <div className="top-right-links">
        {user?.role.name === 'Admin' || user?.role.name === 'Owner' ? (
          <Link to="/admin">Admin Page</Link>
        ) : null}
        {user?.role.name === 'Moderator' || user?.role.name === 'Admin' || user?.role.name === 'Owner' ? (
          <Link to="/moderator">Moderator Page</Link>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
