import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Ranking.css'; // For custom styles

interface Gladiator {
  id: number;
  name: string;
  level: number;
  experience: number;
  kills: number;
}

const Ranking: React.FC = () => {
  const [gladiators, setGladiators] = useState<Gladiator[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchRanking(currentPage);
  }, [currentPage]);

  const fetchRanking = async (page: number) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('/api/gladiator/ranking', {
        params: { page },
        withCredentials: true
      });

      setGladiators(response.data.gladiators);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching ranking:', error);
      setError('Failed to load gladiator rankings. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="ranking-container">
      <h1 className="ranking-title">Gladiator Rankings</h1>

      {error && <p className="error-message">{error}</p>}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className="ranking-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Level</th>
                <th>Experience</th>
                <th>Kills</th>
              </tr>
            </thead>
            <tbody>
              {gladiators.map((gladiator, index) => (
                <tr key={gladiator.id}>
                  <td>{(currentPage - 1) * 20 + index + 1}</td>
                  <td>{gladiator.name}</td>
                  <td>{gladiator.level}</td>
                  <td>{gladiator.experience}</td>
                  <td>{gladiator.kills}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-container">
            <button
              className="pagination-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="pagination-info">
              {currentPage}/{totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Ranking;
