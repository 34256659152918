// BattleTest.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGladiator } from '../../contexts/GladiatorContext';

const BattleTest: React.FC = () => {
  const [result, setResult] = useState<any>(null);
  const [deathMatch, setDeathMatch] = useState(false); // State for deathMatch option
  const navigate = useNavigate(); // For redirecting after the battle
  const { gladiator } = useGladiator();

  const handleBattleTest = async () => {
    if (!gladiator) {
      setResult('No gladiator selected.');
      return;
    }

    try {
      const response = await axios.post(
        'https://njordheim.com/api/battle/test',
        {
          teamOne: [
            {
              gladiatorId: gladiator.id,
              strategy: 'normal',
              surrenderHP: 0.2,
            },
            {
              gladiatorId: 14,
              strategy: 'normal',
              surrenderHP: 0.2,
            },
          ],
          teamTwo: [
            {
              gladiatorId: 22,
              strategy: 'normal',
              surrenderHP: 0.2,
            },
            {
              gladiatorId: 24,
              strategy: 'normal',
              surrenderHP: 0.2,
            },
          ],
          playerGladiatorId: gladiator.id,
          deathMatch,
          title: 'Admin Test Battle',
          battleType: 'AdminTestBattle',
        },
        { withCredentials: true }
      );

      const { battleId } = response.data;
      //navigate(`/battle-result/${battleId}`);
      setResult(response.data);
    } catch (error) {
      console.error('Error starting battle:', error);
      setResult('Error starting battle.');
    }
  };

  if (!gladiator) {
    return <div>No gladiator found.</div>;
  }

  return (
    <div>
      <h1>Battle Test</h1>

      <label>
        <input
          type="checkbox"
          checked={deathMatch}
          onChange={(e) => setDeathMatch(e.target.checked)}
        />
        To the Death
      </label>
      <br />
      <button onClick={handleBattleTest}>Start Battle</button>

      <div>
        <h2>Battle Result:</h2>
        {result ? (
          <pre>{JSON.stringify(result, null, 2)}</pre>
        ) : (
          <p>No result yet. Click "Start Battle" to initiate a test.</p>
        )}
      </div>
    </div>
  );
};

export default BattleTest;
