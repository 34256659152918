import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './GladiatorProfile.css';

interface Weapon {
  id: number;
  name: string;
  minDamage: number;
  maxDamage: number;
  dmgCap: number;
  critChance: number;
  critDamage: number;
  weaponType: string;
  skillRec: number;
  breakValue: number;
  currentBreakValue: number;
  actionsPerRound: number;
  weight: number;
}

interface Gladiator {
  name: string;
  race: string;
  sex: string;
  avatar: string;
  level: number;
  experience: number;
  nextExperience: number;
  previousExperience: number;
  health: number;
  maxHealth: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  primarySkillPoints: number;
  secondarySkillPoints: number;
  silver: number;
  totalCritChance: number;
  totalCritDamage: number;
  rounds: number;
  maxRounds: number;
  form: number;
  weaponSkills: {
    axe: number;
    sword: number;
    mace: number;
    staff: number;
    dagger: number;
    chain: number;
    shield: number;
  };
  weapons: {
    mainHand?: Weapon | null;
    offHand?: Weapon | null;
    twoHand?: Weapon | null;
    shield?: Weapon | null;
  };
}

const GladiatorProfile: React.FC = () => {
  const [gladiator, setGladiator] = useState<Gladiator | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGladiator = async () => {
      try {
        const response = await axios.get('https://njordheim.com/api/gladiator', { withCredentials: true });
        setGladiator(response.data);
      } catch (error) {
        setError('Failed to fetch gladiator data');
        console.error(error);
      }
    };

    fetchGladiator();
  }, []);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!gladiator) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="gladiator-profile-container">
      <h1 className="profile-title">Gladiator Profile</h1>
      
      {/* Basic Information */}
      <div className="profile-section">
        <h2>Basic Information</h2>
        <p><strong>Name:</strong> {gladiator.name}</p>
        <p><strong>Race:</strong> {gladiator.race}</p>
        <p><strong>Sex:</strong> {gladiator.sex}</p>
        <p><strong>Level:</strong> {gladiator.level}</p>
        <p><strong>Experience:</strong> {gladiator.experience} / {gladiator.nextExperience}</p>
        <p><strong>Health:</strong> {gladiator.maxHealth}/{gladiator.maxHealth}</p>
        <p><strong>Silver:</strong> {gladiator.silver}</p>
        <p><strong>Form:</strong> {gladiator.form}%</p>
      </div>

      {/* Attributes */}
      <div className="profile-section">
        <h2>Attributes</h2>
        <p><strong>Strength:</strong> {Math.floor(gladiator.strength)}</p>
        <p><strong>Endurance:</strong> {Math.floor(gladiator.endurance)}</p>
        <p><strong>Initiative:</strong> {Math.floor(gladiator.initiative)}</p>
        <p><strong>Dodge:</strong> {Math.floor(gladiator.dodge)}</p>
        <p><strong>Primary Skill Points:</strong> {gladiator.primarySkillPoints}</p>
        <p><strong>Secondary Skill Points:</strong> {gladiator.secondarySkillPoints}</p>
        <p><strong>Total Crit Chance:</strong> {Math.floor(gladiator.totalCritChance)}</p>
        <p><strong>Total Crit Damage:</strong> {Math.floor(gladiator.totalCritDamage)}</p>
      </div>

      {/* Weapon Skills */}
      <div className="profile-section">
        <h2>Weapon Skills</h2>
        {Object.entries(gladiator.weaponSkills).map(([skill, value]) => (
          <p key={skill}><strong>{skill.charAt(0).toUpperCase() + skill.slice(1)} Skill:</strong> {Math.floor(value)}</p>
        ))}
      </div>

      {/* Equipment */}
      <div className="profile-section">
        <h2>Equipment</h2>
        {['mainHand', 'offHand', 'twoHand', 'shield'].map((slot) => {
          const weapon = gladiator.weapons[slot as keyof Gladiator['weapons']];
          return weapon ? (
            <div key={slot}>
              <h3>{slot.charAt(0).toUpperCase() + slot.slice(1)}:</h3>
              <p><strong>Name:</strong> {weapon.name}</p>
              <p><strong>Type:</strong> {weapon.weaponType}</p>
              <p><strong>Damage:</strong> {Math.floor(weapon.minDamage)} - {Math.floor(weapon.maxDamage)}</p>
              <p><strong>Critical Chance:</strong> {Math.floor(weapon.critChance)}</p>
              <p><strong>Critical Damage:</strong> {Math.floor(weapon.critDamage)}</p>
              <p><strong>Weight:</strong> {Math.floor(weapon.weight)}</p>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default GladiatorProfile;
