import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TournamentBracket from './TournamentBracket';
import './TournamentDetail.css';
import { useGladiator } from '../../contexts/GladiatorContext';

interface Gladiator {
  id: number;
  name: string;
}

interface Participant {
  id: number;
  gladiator: Gladiator;
}

interface Match {
  id: number;
  team1Participants: Gladiator[];
  team2Participants: Gladiator[];
  winner_team?: number;
  round_number: number;
  battle_report?: number;
}

interface Tournament {
  id: number;
  name: string;
  type: string;
  status: string;
  num_contestants: number;
  team_size: number;
  start_time: string;
  TournamentParticipants: Participant[];
  TournamentMatches: Match[];
}

const TournamentDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const { gladiator } = useGladiator();
  const [isParticipant, setIsParticipant] = useState(false);
  const [surrenderAt, setSurrenderAt] = useState<number>(50);
  const [strategy, setStrategy] = useState<string>('Normal');

  const fetchTournament = async () => {
    try {
      const response = await axios.get(`/api/tournament/${id}`, { withCredentials: true });
      setTournament(response.data);
      const participantFound = response.data.TournamentParticipants.some(
        (p: Participant) => p.gladiator && p.gladiator.id === gladiator?.id
      );
      
      setIsParticipant(participantFound);
    } catch (error) {
      console.error('Error fetching tournament:', error);
    }
  };

  useEffect(() => {
    fetchTournament();
  }, [id, gladiator?.id]);

  const handleJoin = async () => {
    try {
      await axios.post(
        `/api/tournament/${id}/join`, 
        { gladiatorId: gladiator?.id, surrenderHP: surrenderAt / 100, strategy },
        { withCredentials: true }
      );
      await fetchTournament();
    } catch (error) {
      console.error('Error joining tournament:', error);
    }
  };

  const handleLeave = async () => {
    try {
      await axios.post(
        `/api/tournament/${id}/leave`,
        { gladiatorId: gladiator?.id },
        { withCredentials: true }
      );
      await fetchTournament();
    } catch (error) {
      console.error('Error leaving tournament:', error);
    }
  };

  if (!tournament) return <div>Loading...</div>;

  return (
    <div className="tournament-detail-container">
      <h2>{tournament.name}</h2>
      <p>Type: {tournament.type === 'death' ? 'Death Match' : 'Normal Battle'}</p>
      <p>Status: {tournament.status}</p>
      <p>Participants: {tournament.TournamentParticipants.length} / {tournament.num_contestants * tournament.team_size}</p>
      <p>Starts at: {new Date(tournament.start_time).toLocaleString()}</p>

      {tournament.status === 'scheduled' && (
        <div>
          {isParticipant ? (
            <button onClick={handleLeave} className="leave-button">Leave Tournament</button>
          ) : (
            <div>
              <label>
                Surrender HP:
                <select className="surrenderAt-select" value={surrenderAt} onChange={(e) => setSurrenderAt(parseInt(e.target.value))}>
                  {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map(value => (
                    <option key={value} value={value}>Surrender at {value}%</option>
                  ))}
                </select>
              </label>
              <label>
                Strategy:
                <select className="strategy-select" value={strategy} onChange={(e) => setStrategy(e.target.value)}>
                  <option value="Normal">Normal</option>
                  <option value="Normal: Light">Normal: Light</option>
                  <option value="Normal: Heavy">Normal: Heavy</option>
                  <option value="Offensive">Offensive</option>
                  <option value="Offensive: Light">Offensive: Light</option>
                  <option value="Offensive: Heavy">Offensive: Heavy</option>
                  <option value="Defensive">Defensive</option>
                  <option value="Defensive: Light">Defensive: Light</option>
                  <option value="Defensive: Heavy">Defensive: Heavy</option>
                  <option value="Berserk">Berserk</option>
                  <option value="Berserk: Light">Berserk: Light</option>
                  <option value="Berserk: Heavy">Berserk: Heavy</option>
                </select>
              </label>
              <button onClick={handleJoin} className="join-button">Join Tournament</button>
            </div>
          )}
        </div>
      )}

      <h3>Bracket</h3>
      {['locked', 'ongoing', 'completed'].includes(tournament.status) ? (
        <TournamentBracket matches={tournament.TournamentMatches} />
      ) : (
        <p>Bracket will be available once the tournament starts.</p>
      )}
    </div>
  );
};

export default TournamentDetail;
