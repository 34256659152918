import React from 'react';
import { Link } from 'react-router-dom';
import './TournamentBracket.css';

interface Gladiator {
  name: string;
}

interface Match {
  id: number;
  team1Participants: Gladiator[];
  team2Participants: Gladiator[];
  winner_team?: number;
  round_number: number;
  battle_report?: number;
}

interface BracketProps {
  matches: Match[];
}

const TournamentBracket: React.FC<BracketProps> = ({ matches }) => {
  const rounds = matches.reduce((acc, match) => {
    if (!acc[match.round_number]) acc[match.round_number] = [];
    acc[match.round_number].push(match);
    return acc;
  }, {} as { [key: number]: Match[] });

  return (
    <div className="tournament-bracket">
      {Object.keys(rounds).map((roundNumber) => (
        <div key={roundNumber} className="round">
          <h3>Round {roundNumber}</h3>
          <div className="matches">
            {rounds[parseInt(roundNumber)].map((match) => (
              <div key={match.id} className="match">
                <div className="team">
                  {match.team1Participants.map((participant) => (
                    <span key={participant.name}>{participant.name}</span>
                  ))}
                </div>
                <div className="vs">VS</div>
                <div className="team">
                  {match.team2Participants.map((participant) => (
                    <span key={participant.name}>{participant.name}</span>
                  ))}
                </div>
                {match.winner_team && (
                  <div className="winner">Winner: Team {match.winner_team}</div>
                )}
                {match.battle_report && (
                  <Link
                    to={`/battle-result/${match.battle_report}`}
                    className="view-battle-report-link"
                  >
                    View Battle Report
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TournamentBracket;
