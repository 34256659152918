import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGladiator } from '../../contexts/GladiatorContext';
import Tooltip from './ItemTooltip';
import { Item } from '../types/Item';
import './Inventory.css';

interface ActivePotion {
  name: string;
  effect: string;
  effectEndTime: string;
}

interface PotionWithTimeLeft extends ActivePotion {
  hours: number;
  minutes: number;
  seconds: number;
}

const Inventory: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [activePotions, setActivePotions] = useState<PotionWithTimeLeft[]>([]);
  const { gladiatorId } = useGladiator();

  const fetchItems = async () => {
    try {
      const response = await axios.get(`/api/inventory/${gladiatorId}`, { withCredentials: true });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching inventory items:', error);
    }
  };

  const fetchActivePotions = async () => {
    try {
      const response = await axios.get(`/api/inventory/${gladiatorId}/activePotions`, { withCredentials: true });
      const potions = response.data;
      const calculatedPotions = potions.map((potion: ActivePotion) => calculateTimeLeft(potion));
      setActivePotions(calculatedPotions);
    } catch (error) {
      console.error('Error fetching active potions:', error);
    }
  };

  const calculateTimeLeft = (potion: ActivePotion): PotionWithTimeLeft => {
    const effectEndTime = new Date(potion.effectEndTime).getTime();
    const currentTime = new Date().getTime();
    const remainingTime = effectEndTime - currentTime;

    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    return { ...potion, hours, minutes, seconds };
  };

  useEffect(() => {
    fetchItems();
    fetchActivePotions();

    const interval = setInterval(() => {
      setActivePotions(prevPotions =>
        prevPotions.map(potion => calculateTimeLeft(potion))
      );
    }, 1000); // Update the countdown every second locally

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [gladiatorId]);

  const handleEquip = async (itemId: number, slot: string) => {
    try {
      await axios.post(`/api/inventory/equip`, { gladiatorId, itemId, slot }, { withCredentials: true });
      await fetchItems();
    } catch (error) {
      console.error('Error equipping item:', error);
    }
  };

  const handleUnequip = async (itemId: number, slot: string) => {
    try {
      await axios.post(`/api/inventory/unequip`, { gladiatorId, itemId, slot }, { withCredentials: true });
      await fetchItems();
    } catch (error) {
      console.error('Error unequipping item:', error);
    }
  };

  const handleUsePotion = async (itemId: number) => {
    try {
      await axios.post(`/api/inventory/usePotion`, { gladiatorId, itemId }, { withCredentials: true });
      await fetchItems();
    } catch (error) {
      console.error('Error using potion:', error);
    }
  };

  const renderEquipButton = (item: Item) => {
    if (item.itemCategory === 'Material') return null;
    if (item.slot === 'potion' && item.itemCategory === 'Potion') {
      return <button onClick={() => handleUsePotion(item.id)} className="inventory-button">Use</button>;
    }

    if (item.itemCategory === 'Potion') {
      return <button onClick={() => handleUsePotion(item.id)} className="inventory-button">Use</button>;
    }

    if (item.itemCategory === 'Weapon') {
      return (
        <>
          {item.slot === 'oneHand' && (
            <>
              <button onClick={() => handleEquip(item.id, 'mainHand')} className="inventory-button">Equip Main Hand</button>
              {item.canEquipInOffHand && (
                <button onClick={() => handleEquip(item.id, 'offHand')} className="inventory-button">Equip Off Hand</button>
              )}
            </>
          )}
          {item.slot === 'twoHand' && (
            <button onClick={() => handleEquip(item.id, 'twoHand')} className="inventory-button">Equip Two-Handed</button>
          )}
        </>
      );
    }

    if (item.itemCategory === 'Armor' || item.itemCategory === 'Accessory') {
      return (
        <button onClick={() => handleEquip(item.id, item.slot)} className="inventory-button">
          Equip {item.slot.charAt(0).toUpperCase() + item.slot.slice(1)}
        </button>
      );
    }

    if (item.itemCategory === 'Shield') {
      return <button onClick={() => handleEquip(item.id, 'offHand')} className="inventory-button">Equip Shield (Off Hand)</button>;
    }

    return null;
  };

  const equippedItems = items.filter(item => item.equipped);
  const unequippedItems = items.filter(item => !item.equipped);

  return (
    <div className="inventory-container">
      <div className="inventory-section">
        <h2>Equipped Items</h2>
        {equippedItems.length > 0 ? (
          <ul className="inventory-list">
            {equippedItems.map(item => (
              <li key={item.id} className="inventory-list-item">
                <Tooltip item={item}>
                  <strong>{item.name}</strong>
                </Tooltip>
                <p>{item.description}</p>
                <button onClick={() => handleUnequip(item.id, item.slot)} className="inventory-button unequip-button">
                  Unequip
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No items equipped.</p>
        )}

        <div className="active-potions-section">
          <h2>Active Potions</h2>
          {activePotions.length > 0 ? (
            <ul className="potion-list">
              {activePotions.map((potion, index) => (
                <li key={index} className="potion-list-item">
                  <strong>{potion.name}</strong> - {potion.effect} <br />
                  Time left: {potion.hours}h {potion.minutes}m {potion.seconds}s
                </li>
              ))}
            </ul>
          ) : (
            <p>No active potions.</p>
          )}
        </div>
      </div>

      <div className="inventory-section">
        <h2>Unequipped Items</h2>
        {unequippedItems.length > 0 ? (
          <table className="inventory-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {unequippedItems.map(item => (
                <tr key={item.id}>
                  <td>
                    <Tooltip item={item}>
                      <span>{item.name}</span>
                    </Tooltip>
                  </td>
                  <td>{item.description}</td>
                  <td>{item.quantity}</td>
                  <td>{renderEquipButton(item)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No unequipped items available.</p>
        )}
      </div>
    </div>
  );
};

export default Inventory;
