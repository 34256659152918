import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LevelUp.css';
import { useNavigate } from 'react-router-dom';
import { useGladiator } from '../../contexts/GladiatorContext';

interface PrimaryPoints {
  health: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  axe: number;
  sword: number;
  mace: number;
  staff: number;
  shield: number;
  dagger: number;
  chain: number;
}

interface SecondaryPoints {
  learning: number;
  luck: number;
  discipline: number;
  leadership: number;
  provocation: number;
}

interface GladiatorData extends PrimaryPoints, SecondaryPoints {
  id: number;
  name: string;
  level: number;
  primarySkillPoints: number;
  secondarySkillPoints: number;
  maxHealth: number;
}

interface RaceBonuses {
  health: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  learning: number;
  luck: number;
  discipline: number;
  axe: number;
  sword: number;
  mace: number;
  staff: number;
  shield: number;
  dagger: number;
  chain: number;
}

const LevelUp: React.FC = () => {
  const navigate = useNavigate();
  const { gladiator, setGladiator } = useGladiator();
  const [userGladiator, setUserGladiator] = useState<GladiatorData | null>(null);
  const [allocatedPrimaryPoints, setAllocatedPrimaryPoints] = useState<PrimaryPoints>({
    health: 0,
    strength: 0,
    endurance: 0,
    initiative: 0,
    dodge: 0,
    axe: 0,
    sword: 0,
    mace: 0,
    staff: 0,
    shield: 0,
    dagger: 0,
    chain: 0,
  });
  const [allocatedSecondaryPoints, setAllocatedSecondaryPoints] = useState<SecondaryPoints>({
    learning: 0,
    luck: 0,
    discipline: 0,
    leadership: 0,
    provocation: 0,
  });
  const [primaryPointsLeft, setPrimaryPointsLeft] = useState<number>(gladiator?.primarySkillPoints || 0);
  const [secondaryPointsLeft, setSecondaryPointsLeft] = useState<number>(gladiator?.secondarySkillPoints || 0);
  const [raceBonuses, setRaceBonuses] = useState<RaceBonuses | null>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/auth/user', { withCredentials: true });
      const user = response.data;
      const gladiatorData = user.gladiators[0];
      setUserGladiator(gladiatorData);
      setPrimaryPointsLeft(gladiatorData.primarySkillPoints);
      setSecondaryPointsLeft(gladiatorData.secondarySkillPoints);
      setGladiator(gladiatorData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data.');
      setLoading(false);
    }
  };

  const fetchRaceBonuses = async () => {
    try {
      const response = await axios.get(`/api/gladiator/race-bonus?race=${gladiator?.race}`);
      const bonusData = response.data[0];
      setRaceBonuses({
        health: bonusData.health,
        strength: bonusData.strength,
        endurance: bonusData.endurance,
        initiative: bonusData.initiative,
        dodge: bonusData.avoidance,
        learning: bonusData.learningCapacity,
        luck: bonusData.luck,
        discipline: bonusData.discipline,
        axe: bonusData.weaponAxes,
        sword: bonusData.weaponSwords,
        mace: bonusData.weaponMaces,
        staff: bonusData.weaponStaves,
        shield: bonusData.weaponShields,
        dagger: bonusData.weaponDaggers,
        chain: bonusData.weaponChain,
      });
    } catch (error) {
      console.error('Error fetching race bonuses:', error);
      setError('Failed to fetch race bonuses.');
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchRaceBonuses();
  }, []);

  const handlePrimaryChange = (skill: keyof PrimaryPoints, value: number) => {
    const newAllocatedPoints = { ...allocatedPrimaryPoints, [skill]: value };
    const totalPoints = Object.values(newAllocatedPoints).reduce((a, b) => a + b, 0);
    if (totalPoints <= userGladiator!.primarySkillPoints) {
      setAllocatedPrimaryPoints(newAllocatedPoints);
      setPrimaryPointsLeft(userGladiator!.primarySkillPoints - totalPoints);
    }
  };

  const handleSecondaryChange = (skill: keyof SecondaryPoints, value: number) => {
    const newAllocatedPoints = { ...allocatedSecondaryPoints, [skill]: value };
    const totalPoints = Object.values(newAllocatedPoints).reduce((a, b) => a + b, 0);
    if (totalPoints <= userGladiator!.secondarySkillPoints) {
      setAllocatedSecondaryPoints(newAllocatedPoints);
      setSecondaryPointsLeft(userGladiator!.secondarySkillPoints - totalPoints);
    }
  };

  const handleSubmit = async () => {
    if (primaryPointsLeft !== 0 || secondaryPointsLeft !== 0) {
      setError('You must allocate all points.');
      return;
    }
  
    try {
      const response = await axios.post(
        '/api/gladiator/level-up',
        {
          primarySkillPoints: Object.values(allocatedPrimaryPoints).reduce((a, b) => a + b, 0),
          secondarySkillPoints: Object.values(allocatedSecondaryPoints).reduce((a, b) => a + b, 0),
          attributes: { ...allocatedPrimaryPoints, ...allocatedSecondaryPoints },
        },
        { withCredentials: true }
      );
  
      const updatedGladiator = response.data;
      setGladiator(updatedGladiator);
      localStorage.setItem('gladiator', JSON.stringify(updatedGladiator));
      navigate('/gladiator');
    } catch (error) {
      console.error('Error saving skill points:', error);
      setError('Failed to save skill points.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userGladiator) {
    return <div>No gladiator found. Please refresh the page.</div>;
  }

  const applyRaceBonus = (skill: string, baseValue: number) => {
    const multiplier = skill === 'leadership' || skill === 'provocation' ? 1.0 : raceBonuses?.[skill as keyof RaceBonuses] || 1;
    return (baseValue * multiplier).toFixed(0);
  };

  return (
    <div className="level-up-container">
      <h2>Level Up</h2>
      <div className="gladiator-info">
        <p>Gladiator Name: {userGladiator.name}</p>
        <p>Current Level: {userGladiator.level}</p>
        <p>Available Primary Skill Points: {userGladiator.primarySkillPoints}</p>
        <p>Available Secondary Skill Points: {userGladiator.secondarySkillPoints}</p>
      </div>
      <div className="points-allocation">
        <h3>Allocate Primary Skill Points</h3>
        <div className="skills-table">
          <div className="skills-header">
            <span>Skill</span>
            <span>Current Points</span>
            <span>Planned</span>
            <span>New Total</span>
            <span>Racial Traits</span>
          </div>
          {Object.keys(allocatedPrimaryPoints).map((skill) => (
            <div key={skill} className="skill-row">
              <span>{skill.charAt(0).toUpperCase() + skill.slice(1)}</span>
              <span>{skill === 'health' ? userGladiator.maxHealth : userGladiator[skill as keyof PrimaryPoints] || 0}</span>
              <input
                className="skill-input"
                type="number"
                value={allocatedPrimaryPoints[skill as keyof PrimaryPoints]}
                onChange={(e) => handlePrimaryChange(skill as keyof PrimaryPoints, parseInt(e.target.value, 10) || 0)}
                min="0"
                max={userGladiator.primarySkillPoints}
              />
              <span>
                {(skill === 'health' ? userGladiator.maxHealth : userGladiator[skill as keyof PrimaryPoints] || 0) + allocatedPrimaryPoints[skill as keyof PrimaryPoints]}
              </span>
              <span>
                {applyRaceBonus(
                  skill,
                  (skill === 'health' ? userGladiator.maxHealth : userGladiator[skill as keyof PrimaryPoints] || 0) + allocatedPrimaryPoints[skill as keyof PrimaryPoints]
                )}
              </span>
            </div>
          ))}
        </div>
        <p>Primary Points Left: {primaryPointsLeft}</p>
      </div>
      <div className="points-allocation">
        <h3>Allocate Secondary Skill Points</h3>
        <div className="skills-table">
          <div className="skills-header">
            <span>Skill</span>
            <span>Current Points</span>
            <span>Planned</span>
            <span>New Total</span>
            <span>Racial Traits</span>
          </div>
          {Object.keys(allocatedSecondaryPoints).map((skill) => (
            <div key={skill} className="skill-row">
              <span>{skill.charAt(0).toUpperCase() + skill.slice(1)}</span>
              <span>{userGladiator[skill as keyof SecondaryPoints] || 0}</span>
              <input
                className="skill-input"
                type="number"
                value={allocatedSecondaryPoints[skill as keyof SecondaryPoints]}
                onChange={(e) => handleSecondaryChange(skill as keyof SecondaryPoints, parseInt(e.target.value, 10) || 0)}
                min="0"
                max={userGladiator.secondarySkillPoints}
              />
              <span>
                {(userGladiator[skill as keyof SecondaryPoints] || 0) + allocatedSecondaryPoints[skill as keyof SecondaryPoints]}
              </span>
              <span>
                {applyRaceBonus(
                  skill,
                  (userGladiator[skill as keyof SecondaryPoints] || 0) + allocatedSecondaryPoints[skill as keyof SecondaryPoints]
                )}
              </span>
            </div>
          ))}
        </div>
        <p>Secondary Points Left: {secondaryPointsLeft}</p>
      </div>
      {error && <p className="error">{error}</p>}
      <button onClick={handleSubmit} className="save-button">
        Save Points
      </button>
    </div>
  );
};

export default LevelUp;
