import React, { useState } from 'react';
import axios from 'axios';
import './DiceGame.css';

interface DiceGameProps {
  gladiatorId: number;
  initialSilver: number;
}

const DiceGame: React.FC<DiceGameProps> = ({ gladiatorId, initialSilver }) => {
  const [bet, setBet] = useState(0);
  const [result, setResult] = useState<string | null>(null);
  const [silver, setSilver] = useState(initialSilver);

  const handlePlay = async () => {
    if (bet <= 0) {
      setResult('Please enter a valid bet.');
      return;
    }

    try {
      const response = await axios.post('/api/tavern/play-dice', {
        gladiatorId,
        bet,
      });

      // Update silver and result from server response
      setResult(response.data.message);
      setSilver(response.data.newSilver);
    } catch (error) {
      const errorMessage = (error as any).response?.data?.message || 'Failed to play dice game';
      setResult(errorMessage);
    }
  };

  return (
    <div className="dice-game">
      <h3>Dice Game</h3>
      <p>Bet silver and try to roll exactly 7 with two dice to win big!</p>
      <div className="bet-controls">
        <input
          type="number"
          min="1"
          max={silver}
          value={bet > 0 ? bet : ''}
          onChange={(e) => setBet(Number(e.target.value))}
          placeholder="Enter your bet"
          className="plain-input"
        />
        <button onClick={handlePlay}>Roll Dice</button>
      </div>
      {result && <p className="result">{result}</p>}
      <p>Your silver: {silver}</p>
    </div>
  );
};

export default DiceGame;
