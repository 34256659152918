import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ViewTournaments.css';

interface Tournament {
    id: number;
    name: string;
    type: string;
    status: string;
    num_contestants: number;
    start_time: string;
}

const ViewTournaments: React.FC = () => {
    const [tournaments, setTournaments] = useState<Tournament[]>([]);

    useEffect(() => {
        const fetchTournaments = async () => {
            try {
                const response = await axios.get('/api/tournament', { withCredentials: true });
                const filteredTournaments = response.data.filter((tournament: Tournament) =>
                    ['scheduled', 'locked', 'ongoing'].includes(tournament.status)
                );
                setTournaments(filteredTournaments);
            } catch (error) {
                console.error('Error fetching tournaments:', error);
            }
        };
    
        fetchTournaments();
    }, []);

    return (
        <div className="tournament-list-container">
            <h2>Available Tournaments</h2>
            <ul className="tournament-list">
                {tournaments.map((tournament) => (
                    <li key={tournament.id} className="tournament-item">
                        <h3>{tournament.name}</h3>
                        <p>Type: {tournament.type === 'death' ? 'Death Match' : 'Normal Battle'}</p>
                        <p>Status: {tournament.status}</p>
                        <p>Contestants: {tournament.num_contestants}</p>
                        <p>Starts at: {new Date(tournament.start_time).toLocaleString()}</p>
                        <Link to={`/tournament/${tournament.id}`} className="tournament-link">
                            View Details
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ViewTournaments;
