import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useSocket } from '../../contexts/SocketContext';
import { useGladiator } from '../../contexts/GladiatorContext';
import './GladiatorBar.css';
import battleNotificationSound from '../../sounds/battle_notification.mp3';

interface GladiatorStats {
  name?: string;
  race?: string;
  sex?: string;
  avatar?: number;
  level?: number;
  health?: number;
  maxHealth?: number;
  experience?: number;
  nextExperience?: number;
  previousExperience?: number;
  rounds?: number;
  form?: number;
  silver?: number;
  primarySkillPoints?: number;
  secondarySkillPoints?: number;
}

const GladiatorBar: React.FC = () => {
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { gladiator, setGladiator } = useGladiator();
  const [gladiatorStats, setGladiatorStats] = useState<GladiatorStats | null>(gladiator);

  // State to manage battle notification
  const [battleNotification, setBattleNotification] = useState<{ battleId: number } | null>(null);
  const notificationAudioRef = useRef(new Audio(battleNotificationSound));
  const [levelUpNotification, setLevelUpNotification] = useState(false);
  const [regenTimerProgress, setRegenTimerProgress] = useState<number>(0);

  useEffect(() => {
    if (socket) {
      // Listen for gladiator stats updates
      socket.on('update_gladiator_stats', (updatedStats: Partial<GladiatorStats>) => {
        setGladiatorStats((prevState: GladiatorStats | null) => ({
          ...prevState,
          ...updatedStats,
        }));
      });

      // Listen for battle notification
      socket.on('battle_notification', (battleId: number) => {
        setBattleNotification({ battleId });
        notificationAudioRef.current.play().catch((error) => {
          console.error('Failed to play sound:', error);
        });

        setTimeout(() => {
          setBattleNotification(null);
        }, 30000);
      });

      // Listen for level up notification
      socket.on('level_up_notification', () => {
        setLevelUpNotification(true);
      });

      // Listen for gladiator regen event and update state directly
      socket.on('gladiator_regen', (regen: { hp: number, rounds: number }) => {
        setGladiatorStats((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            health: regen.hp,
            rounds: regen.rounds,
          };
        });
      });

      return () => {
        socket.off('update_gladiator_stats');
        socket.off('battle_notification');
        socket.off('level_up_notification');
        socket.off('gladiator_regen');
      };
    }
  }, [socket]);

  useEffect(() => {
    if (gladiator) {
      setGladiatorStats(gladiator);
  
      // Check if there are any skill points left to determine the level-up button's visibility
      if (gladiator.primarySkillPoints > 0 || gladiator.secondarySkillPoints > 0) {
        setLevelUpNotification(true);
      } else {
        setLevelUpNotification(false);
      }
    }
  }, [gladiator]);

  // Update regen timer every second
  useEffect(() => {
    const interval = setInterval(() => {
      setRegenTimerProgress(Date.now() % 180000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (!gladiatorStats) {
    return <p>No gladiator found.</p>;
  }

  const healthPercentage = (gladiatorStats.health ?? 0) / (gladiatorStats.maxHealth ?? 1) * 100;

  // Calculate experience for current level progress
  const currentLevelExp = (gladiatorStats.experience ?? 0) - (gladiatorStats.previousExperience ?? 0);
  const levelExpNeeded = (gladiatorStats.nextExperience ?? 0) - (gladiatorStats.previousExperience ?? 0);
  const hasLeveledUp = currentLevelExp >= levelExpNeeded;
  const experiencePercentage = hasLeveledUp ? 100 : (currentLevelExp / levelExpNeeded) * 100;
  const experienceDisplay = hasLeveledUp ? "Leveled up!" : `${currentLevelExp} / ${levelExpNeeded} Exp`;

  // Calculate remaining time for the regen timer in minutes and seconds
  const minutes = Math.floor((180000 - regenTimerProgress) / 60000);
  const seconds = Math.floor(((180000 - regenTimerProgress) % 60000) / 1000);

  const handleViewBattle = () => {
    if (battleNotification) {
      navigate(`/battle-result/${battleNotification.battleId}`);
    }
    setBattleNotification(null);
  };

  const handleDismissNotification = () => {
    setBattleNotification(null);
  };

  const handleLevelUp = () => {
    navigate('/level-up');
    setLevelUpNotification(false);
  };

  return (
    <div className="gladiator-bar">
      <div className="content-container">
        <div className="gladiator-avatar">
          <img
            src={`/img/avatars/${gladiatorStats.race?.toLowerCase() ?? 'default'}.${gladiatorStats.sex?.toLowerCase() ?? 'default'}.${gladiatorStats.avatar ?? 1}.png`}
            alt={gladiatorStats.name ?? 'Unknown Gladiator'}
          />
          <h2>{gladiatorStats.name ?? 'Unknown Gladiator'}</h2>
          <p>Level: {gladiatorStats.level ?? 1}</p>

          {battleNotification && (
            <div className="battle-notification">
              <p>I just participated in a battle, want to see it?</p>
              <button onClick={handleViewBattle}>View Battle</button>
              <button onClick={handleDismissNotification}>Close</button>
            </div>
          )}
        </div>

        <div className="gladiator-stats">
          <div className="stat">
            <div className="progress-bar hp-bar">
              <div className="fill" style={{ width: `${healthPercentage}%` }} />
              <span>{Math.floor(gladiatorStats.health ?? 0)} / {Math.floor(gladiatorStats.maxHealth ?? 1)} HP</span>
            </div>
          </div>
          <div className="stat">
            <div className="progress-bar ep-bar">
              <div className="fill" style={{ width: `${experiencePercentage}%` }} />
              <span>{experienceDisplay}</span>
            </div>
          </div>

          <div className="stat">
            <div className="progress-bar regen-bar">
              <div className="fill" style={{ width: `${(regenTimerProgress / 180000) * 100}%` }} />
              <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds} Regen</span>
            </div>
          </div>

          <div className="gladiator-form">
            <div className="stat">
              <label>Form:</label>
              <span>{gladiatorStats.form}%</span>
            </div>
            <div className="stat">
              <label>Rounds:</label>
              <span>{gladiatorStats.rounds ?? 0} / 125</span>
            </div>
          </div>
          <div className="stat">
            <label>Silver:</label>
            <span>{gladiatorStats.silver}</span>
          </div>
        </div>

        {levelUpNotification && (
          <div className="level-up-notification">
            <button onClick={handleLevelUp} className="level-up-button">Level Up!</button>
          </div>
        )}

        <div className="gladiator-links">
          <h3>Gladiator Links</h3>
          <ul>
            <li><Link to="/gladiator">Profile</Link></li>
            <li><Link to="/bio">Bio</Link></li>
            <li><Link to="/attributes">Attributes</Link></li>
            <li><Link to="/battle-history">Battle History</Link></li>
            <li><Link to="/statistics">Statistics</Link></li>
            <li><Link to="/inventory">Inventory</Link></li>
            <li><Link to="/profession">Profession</Link></li>
            <li><Link to="/achievements">Achievements</Link></li>
            <li><Link to="/activity">Activity</Link></li>
          </ul>
        </div>

        <div className="account-links">
          <h3>Account Links</h3>
          <ul>
            <li><Link to="/gladiators">Gladiators</Link></li>
            <li><Link to="/notes">Notes</Link></li>
            <li><Link to="/friends-list">Friends List</Link></li>
            <li><Link to="/mail">Mail</Link></li>
            <li><Link to="/account">Account</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GladiatorBar;
