import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useGladiator } from '../../../contexts/GladiatorContext';
import categories from '../../types/categories';
import Tooltip from '../ItemTooltip';
import './Browse.css';

interface Item {
  id: number;
  name: string;
  description: string;
  weight: number;
  value: number;
  itemCategory: string;
  slot: string;
}

interface ListedItem {
  id: number;
  Item: Item;
  quantity: number;
  price: number;
  expiresMasked: string;
}

interface BrowseProps {
  category: keyof typeof categories | null;
  subcategory: string | null;
  searchTerm: string;
}

const ITEMS_PER_PAGE = 20;

const Browse: React.FC<BrowseProps> = ({ category, subcategory, searchTerm }) => {
  const [listedItems, setListedItems] = useState<ListedItem[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState<string | null>(null);
  const [selectedQuantities, setSelectedQuantities] = useState<{ [key: number]: number }>({});

  const { gladiator } = useGladiator();

  useEffect(() => {
    setCurrentPage(1);
  }, [category, subcategory, searchTerm]);

  useEffect(() => {
    fetchListedItems();
  }, [category, subcategory, sortField, sortOrder, currentPage, searchTerm]);

  const fetchListedItems = async () => {
    try {
      const response = await axios.get(`/api/auction/items/browse`, {
        params: {
          category,
          subcategory,
          sortField,
          sortOrder,
          page: currentPage,
          limit: ITEMS_PER_PAGE,
          search: searchTerm,
        },
      });
      setListedItems(response.data.items);
      setTotalItems(response.data.totalItems);
      setSelectedQuantities(
        Object.fromEntries(response.data.items.map((item: ListedItem) => [item.id, 1]))
      );
    } catch (error) {
      console.error('Error fetching listed items:', error);
    }
  };

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleBuy = async (itemId: number) => {
    const quantity = selectedQuantities[itemId] || 1;
    try {
      const response = await axios.post(`/api/auction/buy`, { itemId, gladiatorId: gladiator?.id, quantity });
      if (response.data.success) {
        setMessage('Purchase successful!');
        fetchListedItems();
      } else {
        setMessage('Purchase failed: ' + (response.data.error || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error buying item:', error);
      if (axios.isAxiosError(error)) {
        setMessage('Purchase failed: ' + (error.response?.data.error || 'Server error'));
      } else {
        setMessage('Purchase failed: Unknown error');
      }
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleQuantityChange = (itemId: number, value: string) => {
    const newQuantity = Math.max(1, Math.min(Number(value), listedItems.find((item) => item.id === itemId)?.quantity || 1));
    setSelectedQuantities((prev) => ({ ...prev, [itemId]: newQuantity }));
  };

  const totalPages = Math.max(1, Math.ceil(totalItems / ITEMS_PER_PAGE));

  const getSortIcon = (field: string) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? '▲' : '▼';
    }
    return null;
  };

  return (
    <div className="browse-container">
      {message && <div className="feedback-message">{message}</div>}
      <div className="header-bar">
        <span className="header-column name-column" onClick={() => handleSort('name')}>
          Name {getSortIcon('name')}
        </span>
        <span className="header-column expires-column" onClick={() => handleSort('expiresMasked')}>
          Expires {getSortIcon('expiresMasked')}
        </span>
        <span className="header-column qty-column" onClick={() => handleSort('quantity')}>
          Qty {getSortIcon('quantity')}
        </span>
        <span className="header-column price-column" onClick={() => handleSort('price')}>
          Price {getSortIcon('price')}
        </span>
        <span className="header-column action-column">Action</span>
      </div>
      <ul className="item-list">
        {listedItems.length > 0 ? (
          listedItems.map((item) => (
            <li key={item.id} className="item-row">
              <span className="item-column name-column">
                <div className="item-container">
                  <Tooltip item={item.Item}>
                    <img
                      className="item-icon"
                      src={`/img/items/${item.Item.name}.png`}
                      alt={item.Item.name}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = '/img/items/no_img.png';
                      }}
                    />
                    <span className="item-text">{item.Item.name}</span>
                  </Tooltip>
                </div>
              </span>
              <span className="item-column expires-column">{item.expiresMasked}</span>
              <span className="item-column qty-column">
                <input
                  type="number"
                  className="quantity-input"
                  value={selectedQuantities[item.id]}
                  min={1}
                  max={item.quantity}
                  onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                  placeholder="1"
                />
                / {item.quantity}
              </span>
              <span className="item-column price-column">
                {item.price * (selectedQuantities[item.id] || 1)} silver
              </span>
              <button className="auction-buy-btn" onClick={() => handleBuy(item.id)}>Buy</button>
            </li>
          ))
        ) : (
          <p className="no-items-message">No items available for this category.</p>
        )}
      </ul>
  
      <div className="pagination">
        <button 
          onClick={() => handlePageChange(currentPage - 1)}
          className={`page-button ${currentPage === 1 ? 'disabled' : ''}`}
          disabled={currentPage === 1 || totalItems === 0}
        >
          {'< Back'}
        </button>
        <span>Page {currentPage} / {totalPages}</span>
        <button 
          onClick={() => handlePageChange(currentPage + 1)}
          className={`page-button ${currentPage === totalPages ? 'disabled' : ''}`}
          disabled={currentPage === totalPages || totalItems === 0}
        >
          {'Next >'}
        </button>
      </div>
    </div>
  );
};

export default Browse;
