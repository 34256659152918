import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import axios from 'axios';
import io from 'socket.io-client';
import { useAuth } from '../../contexts/AuthContext';
import { useSocket } from '../../contexts/SocketContext';
import './Landing.css';

const Landing: React.FC = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const { socket, setSocket } = useSocket();

  const toggleForm = () => setIsRegistering(!isRegistering);

  useEffect(() => {
    axios
      .get('https://njordheim.com/api/auth/user', { withCredentials: true })
      .then((response) => {
        setUser(response.data);
        if (!socket) {
          const newSocket = io('https://njordheim.com', {
            withCredentials: true,
            transports: ['websocket'],
          });
          setSocket(newSocket);
          newSocket.on('connect', () => console.log('Connected to WebSocket:', newSocket.id));
          newSocket.on('disconnect', (reason) => {
            console.warn('Disconnected:', reason);
            if (reason === 'io server disconnect') newSocket.connect();
          });
        }
        navigate('/home');
      })
      .catch((error) => console.log('User not authenticated:', error));

    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
        console.log('Socket disconnected on unmount');
      }
    };
  }, [setUser, navigate, socket, setSocket]);

  return (
    <div className="landing-page">
      <header className="landing-header">
        <h1>Njordheim RPG</h1>
      </header>
      <div className="landing-content">
        <div className="info-section">
          <h2>Welcome to the World of Njordheim</h2>
          <p>
            Njordheim, a land rich with history, conflict, and intrigue, awaits its next hero. Within the towering walls of
            Njordheim's capital, the arena stands as a testament to the strength and will of those who seek glory and honor.
          </p>
          <p>
            From humble beginnings, train as a gladiator and face daunting foes in brutal combat. Challenge beasts that roam
            the wilderness and test your mettle against seasoned warriors. Each victory brings you closer to renown, while
            each defeat tempers your resolve.
          </p>
          <p>
            Discover the forgotten lore, master the ancient arts of battle, and forge your legacy in a realm where might and
            wit reign supreme. Are you ready to take your place among the legends?
          </p>
          <p>
            Join our community of adventurers, strategists, and storytellers on our{' '}
            <a href="https://discord.gg/ZR3HDBX29C" target="_blank" className="discord-link">
              official Discord
            </a>{' '}
            to connect with other gladiators, share strategies, and stay informed on upcoming tournaments and events.
          </p>
        </div>
        <div className="auth-section">
          <div className="auth-form">
            {isRegistering ? <Register /> : <Login />}
            <button onClick={toggleForm} className="toggle-form-button">
              {isRegistering ? 'Already have an account? Login' : "Don't have an account? Register"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
