import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useGladiator } from '../../../contexts/GladiatorContext';
import './SellOrderForm.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface InventoryItem {
  id: number;
  itemId: number;
  quantity: number;
  Item: {
    id: number;
    name: string;
  };
}

interface Listing {
  price: number;
  quantity: number;
  itemName?: string;
  Item?: {
    name: string;
  };
}

const SellOrderForm: React.FC = () => {
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState(12);
  const [message, setMessage] = useState('');
  const [currentListings, setCurrentListings] = useState<Listing[]>([]);
  const [salesData, setSalesData] = useState<{ date: string; itemsSold: number; avgPrice: number }[]>([]);

  const { user } = useAuth();
  const { gladiator } = useGladiator();

  // Fetch inventory for the gladiator
  const fetchInventory = async () => {
    try {
      if (gladiator?.id) {
        const response = await axios.get(`/api/auction/inventory/${gladiator.id}`);
        setInventory(response.data);
      }
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };

  useEffect(() => {
    fetchInventory();
  }, [gladiator]);

  useEffect(() => {
    if (selectedItemId) {
      axios
        .get(`/api/auction/listings/${selectedItemId}`)
        .then((response) => setCurrentListings(response.data))
        .catch((error) => console.error('Error fetching listings:', error));

      axios
        .get(`/api/auction/sales/${selectedItemId}`)
        .then((response) => setSalesData(response.data))
        .catch((error) => console.error('Error fetching sales history:', error));
    }
  }, [selectedItemId]);

  const handleDurationChange = (selectedDuration: number) => {
    setDuration(selectedDuration);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user || !gladiator || !selectedItemId) {
      setMessage('User, gladiator, or item selection is missing.');
      return;
    }

    try {
      await axios.post('/api/auction/sellorder/create', {
        seller_id: gladiator.id,
        seller_user_id: user.id,
        item_id: parseInt(selectedItemId),
        price: parseInt(price),
        duration,
        quantity: selectedQuantity,
      });

      setMessage('Sell order created successfully!');
      setSelectedItemId('');
      setSelectedQuantity(1);
      setPrice('');
      setDuration(12);

      // Fetch updated inventory after successful order creation
      fetchInventory();
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'Failed to create sell order';
      setMessage(errorMessage);
    }
  };

  const last30Days = Array.from({ length: 30 }, (_, index) =>
    dayjs().subtract(29 - index, 'day').format('YYYY-MM-DD')
);

const salesValues = last30Days.map((date) => {
    const sale = salesData.find((s) => s.date === date);
    return sale ? sale.itemsSold : 0;
});


const chartData = {
    labels: last30Days,
    datasets: [
        {
            label: 'Items Sold',
            data: salesValues,
            backgroundColor: '#4a6b8c',
        },
    ],
};

const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: { type: 'category', title: { display: true, text: 'Date' } },
        y: {
            type: 'linear',
            title: { display: true, text: 'Items Sold' },
            beginAtZero: true,
            ticks: {
                stepSize: 1,
                callback: (value) => Number(value).toFixed(0),
            },
        },
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: (context) => `Items Sold: ${salesValues[context.dataIndex]}`,
                footer: (context) => {
                    const date = last30Days[context[0].dataIndex];
                    const sale = salesData.find((s) => s.date === date);
                    const avgPrice = sale && !isNaN(Number(sale.avgPrice)) ? Number(sale.avgPrice).toFixed(0) : '0';
                    return `Avg Price: ${avgPrice} silver`;
                },                
            },
        },
    },
};

  return (
    <div className="sell-order-form-container">
      <div className="sell-order-form">
        <h2>Create Sell Order</h2>
        {message && <p>{message}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Item To Sell</label>
            <select
              value={selectedItemId}
              onChange={(e) => {
                setSelectedItemId(e.target.value);
                setSelectedQuantity(1);
              }}
              required
            >
              <option value="">Select an item</option>
              {inventory.map((item) => (
                <option key={item.id} value={item.itemId}>
                  {item.Item.name} ({item.quantity} available)
                </option>
              ))}
            </select>
          </div>

          {/* Quantity Selector */}
          {selectedItemId && (
            <div className="form-group">
              <label>Quantity</label>
              <select
                value={selectedQuantity}
                onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
                required
              >
                {[...Array(inventory.find((item) => item.itemId === parseInt(selectedItemId))?.quantity || 1)].map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="form-group">
            <label>Price (/each)</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>Duration & Fee</label>
            <div className="duration-buttons">
              <button
                type="button"
                className={`duration-button ${duration === 12 ? 'active' : ''}`}
                onClick={() => handleDurationChange(12)}
              >
                <span className="duration-time">12 hours</span>
                <span className="duration-cost">(5 silver)</span>
              </button>
              <button
                type="button"
                className={`duration-button ${duration === 24 ? 'active' : ''}`}
                onClick={() => handleDurationChange(24)}
              >
                <span className="duration-time">24 hours</span>
                <span className="duration-cost">(10 silver)</span>
              </button>
              <button
                type="button"
                className={`duration-button ${duration === 48 ? 'active' : ''}`}
                onClick={() => handleDurationChange(48)}
              >
                <span className="duration-time">48 hours</span>
                <span className="duration-cost">(15 silver)</span>
              </button>
            </div>
          </div>

          <button type="submit">Create Sell Order</button>
        </form>
      </div>
      
      <div className="additional-info">
        <div className="current-listings">
          <h3>Current Listings</h3>
          {selectedItemId ? (
            currentListings.length > 0 ? (
              <ul>
                {currentListings.map((listing, index) => (
                  <li key={index}>
                    <span className="item-name">{listing.itemName || 'Unknown Item'}</span>
                    <span className="item-qty">Qty: {listing.quantity}</span>
                    <span className="item-price">Price: {listing.price}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No current listings available for this item.</p>
            )
          ) : (
            <p>Select an item to see current listings.</p>
          )}
        </div>

        <div className="sales-history">
        <h3>Recent Sales (30 Days)</h3>
          <div className="chart-container">
            {salesData.length > 0 ? (
                <div className="chart-container">
                    <Bar data={chartData} options={chartOptions} />
                </div>
            ) : (
              <p>No sales data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellOrderForm;
