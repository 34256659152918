import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSignOutAlt, faTimesCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './LobbyView.css';
import { useGladiator } from '../../contexts/GladiatorContext';
import { useAuth } from '../../contexts/AuthContext';

interface Gladiator {
  id: number;
  userId: number;
  name: string;
  level: number;
}

interface Lobby {
  id: number;
  name: string;
  creatorId: number;
  minLevel: number;
  maxLevel: number;
  maxPlayers: number;
  randomTeams: boolean;
  teamOne: Gladiator[];
  teamTwo: Gladiator[];
}

interface ChatMessage {
  userId: number;
  userName: string;
  message: string;
  createdAt: string;
}

// Set socket with your server URL
const socket = io('https://njordheim.com');

const LobbyView: React.FC = () => {
  const { lobbyId } = useParams<{ lobbyId: string }>();
  const [lobby, setLobby] = useState<Lobby | null>(null);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const { gladiator } = useGladiator();
  const [alreadyInLobby, setAlreadyInLobby] = useState<boolean>(false);
  const [participatingLobbyId, setParticipatingLobbyId] = useState<number | null>(null);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const { user } = useAuth();

  // Gladiator's battle preferences
  const [surrenderAt, setSurrenderAt] = useState<number>(50);
  const [strategy, setStrategy] = useState<string>('Normal');

  // Fetch and manage data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch lobby details
        const lobbyResponse = await axios.get(`/api/team-battle/lobbies/${lobbyId}`, { withCredentials: true });
        setLobby(lobbyResponse.data);
    
        // Fetch participation status
        const participationResponse = await axios.get('/api/team-battle/participation', { withCredentials: true });
        if (participationResponse.data.inLobby) {
          setParticipatingLobbyId(participationResponse.data.lobbyId);
          setAlreadyInLobby(true);
    
          // Fetch gladiator's battle preferences only if they are in the lobby
          const preferencesResponse = await axios.get(`/api/team-battle/lobbies/${lobbyId}/participant/${gladiator.id}`, { withCredentials: true });
          setSurrenderAt(preferencesResponse.data.surrenderHP * 100);
          setStrategy(preferencesResponse.data.strategy);
        } else {
          setParticipatingLobbyId(null);
          setAlreadyInLobby(false);
        }
    
        // Fetch chat history
        const chatResponse = await axios.get(`/api/team-battle/lobbies/${lobbyId}/chat`, { withCredentials: true });
        setChatMessages(chatResponse.data);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data.');
      }
    };    

    fetchData();

    // Join the socket room for the lobby
    socket.emit('join_lobby', { lobbyId });

    // Listen for new chat messages
    socket.on('new_message', (message: ChatMessage) => {
      setChatMessages((prevMessages) => [...prevMessages, message]);
    });

    // Listen for lobby updates
    socket.on('lobby_update', (updatedLobby: Lobby) => {
      setLobby(updatedLobby);
    });

    // Redirect participants to the battle result
    socket.on('redirect_to_battle', ({ battleId }) => {
      navigate(`/battle-result/${battleId}`);
    });

    // Cleanup on component unmount
    return () => {
      socket.emit('leave_lobby', { lobbyId });
      socket.off('new_message');
      socket.off('lobby_update');
      socket.off('redirect_to_battle');
    };
  }, [lobbyId, gladiator.id, navigate]);

  // Update battle preferences
  const updateBattlePreferences = async (surrenderHP: number, strategy: string) => {
    try {
      await axios.post(
        `/api/team-battle/lobbies/${lobbyId}/participant/${gladiator.id}`,
        { surrenderHP, strategy },
        { withCredentials: true }
      );
    } catch (error) {
      console.error('Error updating battle preferences:', error);
    }
  };

  // Handle surrender HP change
  const handleSurrenderHPChange = (value: number) => {
    setSurrenderAt(value);
    updateBattlePreferences(value / 100, strategy);
  };

  // Handle strategy change
  const handleStrategyChange = (value: string) => {
    setStrategy(value);
    updateBattlePreferences(surrenderAt / 100, value);
  };

  // Ensure both teams are full
  const isTeamFull = (team: Gladiator[]) => team.length === (lobby?.maxPlayers ?? 0) / 2;

  // Join a team
  const handleJoinTeam = async (team: 'teamOne' | 'teamTwo') => {
    if (!lobby) return;
  
    // Ensure lobbyId is a valid number before proceeding
    const validLobbyId = Number(lobbyId);
    if (isNaN(validLobbyId)) {
      setError('Invalid lobby ID');
      return;
    }
  
    // Allow participants to join without team restrictions if random teams are enabled
    if (!lobby.randomTeams) {
      if (isTeamFull(lobby.teamOne) && team === 'teamOne') {
        setError('Team One is already full');
        return;
      }
      if (isTeamFull(lobby.teamTwo) && team === 'teamTwo') {
        setError('Team Two is already full');
        return;
      }
    }
  
    try {
      await axios.post(
        `/api/team-battle/lobbies/${validLobbyId}/join`,
        { team },
        { withCredentials: true }
      );
  
      // Update lobby state immediately after joining the team
      setAlreadyInLobby(true);
      setParticipatingLobbyId(validLobbyId);
  
      // Fetch the updated lobby details
      fetchLobbyDetails();
    } catch (error) {
      console.error('Error joining team:', error);
      setError('Failed to join the team.');
    }
  };   

  const fetchLobbyDetails = async () => {
    try {
      const response = await axios.get(`/api/team-battle/lobbies/${lobbyId}`, {
        withCredentials: true,
      });
      setLobby(response.data);
    } catch (error) {
      console.error('Error fetching lobby details:', error);
      setError('Failed to fetch lobby details.');
    }
  };  

  // Handle leaving the lobby
  const handleLeaveLobby = async () => {
    try {
      await axios.post(`/api/team-battle/lobbies/${lobbyId}/leave`, {}, { withCredentials: true });
      navigate('/team-battle');
    } catch (error) {
      console.error('Error leaving lobby:', error);
      setError('Failed to leave the lobby.');
    }
  };

  // Handle deleting the lobby
  const handleDeleteLobby = async () => {
    try {
      await axios.delete(`/api/team-battle/lobbies/${lobbyId}`, { withCredentials: true });
      navigate('/team-battle');
    } catch (error) {
      console.error('Error deleting lobby:', error);
      setError('Failed to delete the lobby.');
    }
  };

  // Handle kicking a participant from the lobby
  const handleKickParticipant = async (gladiatorId: number) => {
    try {
      await axios.delete(`/api/team-battle/lobbies/${lobbyId}/participants/${gladiatorId}`, { withCredentials: true });
      
      // Update lobby to remove the kicked participant
      setLobby((prevLobby) => {
        if (!prevLobby) return prevLobby;
        
        // Ensure teamOne and teamTwo are always arrays
        const updatedTeamOne = prevLobby.teamOne?.filter((g) => g.id !== gladiatorId) || [];
        const updatedTeamTwo = prevLobby.teamTwo?.filter((g) => g.id !== gladiatorId) || [];
  
        return {
          ...prevLobby,
          teamOne: updatedTeamOne,
          teamTwo: updatedTeamTwo,
        };
      });
    } catch (error) {
      console.error('Error kicking participant:', error);
      setError('Failed to kick participant.');
    }
  };

  // Handle sending a new chat message
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      socket.emit('chat_message_lobby', { lobbyId, message: newMessage, userId: user.id, userName: gladiator.name });
      setNewMessage('');
    }
  };

  // Handle starting the battle
  const handleStartBattle = async () => {
    if (!lobby) {
      setError('Lobby information is missing');
      return;
    }

    // Ensure both teams are full before starting
    if (!lobby.randomTeams && (!isTeamFull(lobby.teamOne) || !isTeamFull(lobby.teamTwo))) {
      setError('Both teams must be full before starting the battle.');
      return;
    }

    try {
      const title = lobby.randomTeams
      ? `Random Team Battle - ${Math.ceil((lobby.teamOne.length + lobby.teamTwo.length) / 2)} vs ${Math.floor((lobby.teamOne.length + lobby.teamTwo.length) / 2)}`
      : `Team Battle - ${lobby.teamOne.length} vs ${lobby.teamTwo.length}`;


      // Randomize teams if random teams are enabled
      if (lobby.randomTeams) {
        const participants = [...lobby.teamOne, ...lobby.teamTwo];
        const shuffledParticipants = participants.sort(() => 0.5 - Math.random());

        // Split the shuffled participants into two teams
        const midIndex = Math.floor(shuffledParticipants.length / 2);
        lobby.teamOne = shuffledParticipants.slice(0, midIndex);
        lobby.teamTwo = shuffledParticipants.slice(midIndex);

        setLobby({ ...lobby });
      }

      const response = await axios.post(
        '/api/battle/team-battle',
        {
          lobbyId,
          playerGladiatorId: gladiator.id,
          deathMatch: false,
          title,
          randomTeams: lobby.randomTeams,
        },
        { withCredentials: true }
      );

      socket.emit('redirect_to_battle', { battleId: response.data.battleId });
    } catch (error) {
      console.error('Error starting battle:', error);
      setError('Failed to start the battle.');
    }
  };

  if (!lobby) {
    return <div>Loading...</div>;
  }

  const isOwner = gladiator && lobby && gladiator.id === lobby.creatorId;
  const isInCurrentLobby = participatingLobbyId === (lobby && lobby.id);

  return (
    <div className="lobby-view-container">
      <h1 className="lobby-title">{lobby?.name}</h1>
      <div className="lobby-info">
        <p>Level Range: {lobby?.minLevel} - {lobby?.maxLevel} | Max Participants: {lobby?.maxPlayers} | Random Teams: {lobby?.randomTeams ? 'Yes' : 'No'}</p>
      </div>

      {lobby.randomTeams ? (
        <div className="random-teams">
          <h3>Participants</h3>
          <ul className="participant-list">
            {[...lobby.teamOne, ...lobby.teamTwo].map((gladiator, index) => (
              <li key={gladiator.id} className="participant-item">
                {gladiator.name} (Level {gladiator.level})
                {gladiator.userId === user.id && (
                  <>
                    {/* Surrender HP and Strategy menus */}
                    <div>
                      <select
                        className="surrenderAt-select"
                        value={surrenderAt}
                        onChange={(e) => handleSurrenderHPChange(parseInt(e.target.value))}
                        required
                      >
                        {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map((value) => (
                          <option key={value} value={value}>
                            Surrender at {value}%
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <select
                        className="strategy-select"
                        value={strategy}
                        onChange={(e) => handleStrategyChange(e.target.value)}
                        required
                      >
                        <option value="Normal">Normal</option>
                        <option value="Normal: Light">Normal: Light</option>
                        <option value="Normal: Heavy">Normal: Heavy</option>
                        <option value="Offensive">Offensive</option>
                        <option value="Offensive: Light">Offensive: Light</option>
                        <option value="Offensive: Heavy">Offensive: Heavy</option>
                        <option value="Defensive">Defensive</option>
                        <option value="Defensive: Light">Defensive: Light</option>
                        <option value="Defensive: Heavy">Defensive: Heavy</option>
                        <option value="Berserk">Berserk</option>
                        <option value="Berserk: Light">Berserk: Light</option>
                        <option value="Berserk: Heavy">Berserk: Heavy</option>
                      </select>
                    </div>
                  </>
                )}
                {isOwner && gladiator.id !== lobby.creatorId && (
                  <button className="kick-button" onClick={() => handleKickParticipant(gladiator.id)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </button>
                )}
              </li>
            ))}
          </ul>
          {!alreadyInLobby && (
            <button className="join-button" onClick={() => handleJoinTeam('teamOne')}>
              Join
            </button>
          )}
        </div>
      ) : (
        <div className="teams">
          {/* Team One */}
          <div className="team">
            <h3>Team 1</h3>
            <ul>
              {lobby.teamOne.map((gladiator) => (
                <li key={gladiator.id} className="participant-item">
                  {gladiator.name} (Level {gladiator.level})
                  {gladiator.userId === user.id && (
                    <>
                      <div>
                        <select
                          className="surrenderAt-select"
                          value={surrenderAt}
                          onChange={(e) => handleSurrenderHPChange(parseInt(e.target.value))}
                          required
                        >
                          {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map((value) => (
                            <option key={value} value={value}>
                              Surrender at {value}%
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <select
                          className="strategy-select"
                          value={strategy}
                          onChange={(e) => handleStrategyChange(e.target.value)}
                          required
                        >
                          <option value="Normal">Normal</option>
                          <option value="Normal: Light">Normal: Light</option>
                          <option value="Normal: Heavy">Normal: Heavy</option>
                          <option value="Offensive">Offensive</option>
                          <option value="Offensive: Light">Offensive: Light</option>
                          <option value="Offensive: Heavy">Offensive: Heavy</option>
                          <option value="Defensive">Defensive</option>
                          <option value="Defensive: Light">Defensive: Light</option>
                          <option value="Defensive: Heavy">Defensive: Heavy</option>
                          <option value="Berserk">Berserk</option>
                          <option value="Berserk: Light">Berserk: Light</option>
                          <option value="Berserk: Heavy">Berserk: Heavy</option>
                        </select>
                      </div>
                    </>
                  )}

                  {isOwner && gladiator.id !== lobby.creatorId && (
                    <button className="kick-button" onClick={() => handleKickParticipant(gladiator.id)}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                  )}
                </li>
              ))}
            </ul>
            {!alreadyInLobby && (
              <button className="join-button" onClick={() => handleJoinTeam('teamOne')}>
                Join Team 1
              </button>
            )}
          </div>

          {/* Team Two */}
          <div className="team">
            <h3>Team 2</h3>
            <ul>
              {lobby.teamTwo.map((gladiator) => (
                <li key={gladiator.id} className="participant-item">
                  {gladiator.name} (Level {gladiator.level})
                  {gladiator.userId === user.id && (
                    <>
                      <div>
                        <select
                          className="surrenderAt-select"
                          value={surrenderAt}
                          onChange={(e) => handleSurrenderHPChange(parseInt(e.target.value))}
                          required
                        >
                          {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map((value) => (
                            <option key={value} value={value}>
                              Surrender at {value}%
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <select
                          className="strategy-select"
                          value={strategy}
                          onChange={(e) => handleStrategyChange(e.target.value)}
                          required
                        >
                          <option value="Normal">Normal</option>
                          <option value="Normal: Light">Normal: Light</option>
                          <option value="Normal: Heavy">Normal: Heavy</option>
                          <option value="Offensive">Offensive</option>
                          <option value="Offensive: Light">Offensive: Light</option>
                          <option value="Offensive: Heavy">Offensive: Heavy</option>
                          <option value="Defensive">Defensive</option>
                          <option value="Defensive: Light">Defensive: Light</option>
                          <option value="Defensive: Heavy">Defensive: Heavy</option>
                          <option value="Berserk">Berserk</option>
                          <option value="Berserk: Light">Berserk: Light</option>
                          <option value="Berserk: Heavy">Berserk: Heavy</option>
                        </select>
                      </div>
                    </>
                  )}

                  {isOwner && gladiator.id !== lobby.creatorId && (
                    <button className="kick-button" onClick={() => handleKickParticipant(gladiator.id)}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                  )}
                </li>
              ))}
            </ul>
            {!alreadyInLobby && (
              <button className="join-button" onClick={() => handleJoinTeam('teamTwo')}>
                Join Team 2
              </button>
            )}
          </div>
        </div>
      )}

      {/* Chat Container */}
      <div className="chat-container">
        <div className="chat-messages" ref={chatContainerRef}>
          {chatMessages.map((msg, index) => {
            const date = new Date(msg.createdAt);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return (
              <div key={index} className="chat-message">
                <span className="chat-time">[{hours}:{minutes}]</span>
                <span className="chat-username"> {msg.userName}:</span> {msg.message}
              </div>
            );
          })}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={newMessage}
            placeholder="Type a message..."
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSendMessage();
            }}
          />
          <button onClick={handleSendMessage}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>

      <div className="action-buttons">
        {isOwner && (
          <button className="action-button delete-lobby-button" onClick={handleDeleteLobby}>
            <FontAwesomeIcon icon={faTrashAlt} /> Delete Lobby
          </button>
        )}
        {isInCurrentLobby && (
          <button className="action-button leave-button" onClick={handleLeaveLobby}>
            <FontAwesomeIcon icon={faSignOutAlt} /> Leave Lobby
          </button>
        )}
        <button className="action-button back-button" onClick={() => navigate('/team-battle')}>
          Back to Lobbies
        </button>
        {/* Start Battle Button */}
        {isOwner && (
          <button className="action-button start-battle-button" onClick={handleStartBattle}>
            Start Battle
          </button>
        )}
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default LobbyView;
