import React, { useState } from 'react';
import './ItemTooltip.css';
import { Item } from '../types/Item';
import { useGladiator } from '../../contexts/GladiatorContext';

interface TooltipProps {
  item: Item;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ item, children }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [imgSrc, setImgSrc] = useState(item.img || '/img/no_img.png');
  const { gladiator } = useGladiator();

  const handleMouseOver = (e: React.MouseEvent) => {
    setVisible(true);
    const tooltipWidth = 350;
    const tooltipHeight = 250;

    let x = e.pageX + 10;
    let y = e.pageY + 10;

    if (x + tooltipWidth > window.innerWidth) x = e.pageX - tooltipWidth - 10;
    if (y + tooltipHeight > window.innerHeight) y = e.pageY - tooltipHeight - 10;

    setPosition({ x, y });
  };

  const handleMouseOut = () => {
    setVisible(false);
  };

  const handleImageError = () => setImgSrc('/img/no_img.png');

  const meetsLevelRequirement = item.requiredLevel ? gladiator.level >= item.requiredLevel : true;
  const meetsRaceRequirement =
    !item.itemRace ||
    item.itemRace === 'All' ||
    item.itemRace === gladiator.race ||
    (item.itemRace === 'Redblood' && ['Elf', 'Dwarf', 'Human'].includes(gladiator.race)) ||
    (item.itemRace === 'Blackblood' && ['Orc', 'Goblin', 'Troll'].includes(gladiator.race));

  const meetsTitleRequirement =
    !item.itemTitle ||
    item.itemTitle === 'Normal' ||
    (item.itemTitle === 'Gladiator' && ['Gladiator', 'Legend', 'Dishonorable'].includes(gladiator.title)) ||
    (item.itemTitle === 'Legend' && gladiator.title === 'Legend') ||
    (item.itemTitle === 'Dishonorable' && gladiator.title === 'Dishonorable') ||
    (item.itemTitle === 'Reputable' && gladiator.reputation > 0) ||
    (item.itemTitle === 'Disreputable' && gladiator.reputation < 0);

  const renderItemDetails = () => {
    const formatSlot = (slot: string) => {
      if (slot === 'oneHand') return 'One-Handed';
      if (slot === 'twoHand') return 'Two-Handed';
      return slot;
    };

    switch (item.itemCategory) {
      case 'Weapon':
        return (
          <>
            <p><strong>Min Damage:</strong> {item.minDamage ?? 'N/A'}</p>
            <p><strong>Max Damage:</strong> {item.maxDamage ?? 'N/A'}</p>
            <p><strong>Cap Damage:</strong> {item.dmgCap ?? 'N/A'}</p>
            <p><strong>Crit Chance:</strong> {item.critChance ?? 'N/A'}</p>
            <p><strong>Crit Damage:</strong> {item.critDamage ?? 'N/A'}</p>
            <p><strong>Durability:</strong> {item.breakValue ?? 'N/A'}</p>
            <p><strong>Weapon Type:</strong> {item.weaponType ?? 'N/A'}</p>
            <p><strong>Slot:</strong> {formatSlot(item.slot)}</p>
            <p><strong>Equipable in Off-Hand:</strong> {item.canEquipInOffHand ? 'Yes' : 'No'}</p>
            <p><strong>Actions:</strong> {item.actionsPerRound ?? 'N/A'}</p>
            <p><strong>Skill Recommendation:</strong> {item.skillRecommendation ?? 'N/A'}</p>
          </>
        );
      case 'Shield':
        return (
          <>
            <p><strong>Block Value:</strong> {item.blockValue ?? 'N/A'}</p>
            <p><strong>Durability:</strong> {item.breakValue ?? 'N/A'}</p>
            <p><strong>Actions:</strong> {item.actionsPerRound ?? 'N/A'}</p>
          </>
        );
      case 'Armor':
        return (
          <>
            <p><strong>Absorption:</strong> {item.absorption ?? 'N/A'}</p>
            <p><strong>Armor Type:</strong> {item.armorType ?? 'N/A'}</p>
          </>
        );
      case 'Accessory':
        return (
          <>
            <p><strong>Accessory Type:</strong> {item.accessoryType ?? 'N/A'}</p>
            <p><strong>Bonuses:</strong> {item.bonuses ?? 'N/A'}</p>
          </>
        );
      case 'Potion':
        return (
          <>
            <p><strong>Effect:</strong> {item.potionEffect ?? 'N/A'}</p>
            <p><strong>Duration:</strong> {item.duration ?? 'N/A'} seconds</p>
            <p><strong>Potion Type:</strong> {item.potionType ?? 'N/A'}</p>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div onMouseOver={handleMouseOver} onMouseMove={handleMouseOver} onMouseOut={handleMouseOut} style={{ display: 'inline-block' }}>
        {children}
      </div>
      {visible && (
        <div className="tooltip" style={{ top: position.y, left: position.x }}>
          <div className="tooltip-header">
            <img src={imgSrc} alt={item.name} className="tooltip-image" onError={handleImageError} />
            <h3>{item.name}</h3>
          </div>
          <div className="tooltip-body">
            <p>{item.description}</p>
            <p><strong>Weight:</strong> {item.weight}</p>
            {renderItemDetails()}
            {item.requiredLevel && (
              <p>
                <strong>Level Required:</strong>{' '}
                <span style={{ color: meetsLevelRequirement ? 'inherit' : 'red' }}>{item.requiredLevel}</span>
              </p>
            )}
            {item.itemRace && (
              <p>
                <strong>Races:</strong>{' '}
                <span style={{ color: meetsRaceRequirement ? 'inherit' : 'red' }}>{item.itemRace}</span>
              </p>
            )}
            {item.itemTitle && (
              <p>
                <strong>Title:</strong>{' '}
                <span style={{ color: meetsTitleRequirement ? 'inherit' : 'red' }}>{item.itemTitle}</span>
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
