// Duel.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGladiator } from '../../contexts/GladiatorContext';
import './Duel.css';

const Duel: React.FC = () => {
  const { gladiator } = useGladiator();
  const [maxLevel, setMaxLevel] = useState<number>(gladiator ? gladiator.level + 2 : 1);
  const [strategy, setStrategy] = useState<string>('Normal');
  const [surrenderHP, setSurrenderHP] = useState<number>(() => {
    const savedSurrenderValue = localStorage.getItem('surrenderAt');
    return savedSurrenderValue ? parseInt(savedSurrenderValue) : 50;
  });
  const [result, setResult] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (gladiator) {
      setMaxLevel(gladiator.level + 2);
    }
  }, [gladiator]);

  // Save the selected surrender value to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('surrenderAt', surrenderHP.toString());
  }, [surrenderHP]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!gladiator) {
      setResult('No gladiator selected.');
      return;
    }

    try {
      const response = await axios.post(
        'https://njordheim.com/api/battle/duel/queue',
        {
          gladiatorId: gladiator.id,
          strategy,
          surrenderHP: surrenderHP / 100,
          minLevel: gladiator.level,
          maxLevel,
        },
        { withCredentials: true }
      );

      if (response.data.battleId) {
        navigate(`/battle-result/${response.data.battleId}`);
      } else {
        setResult(response.data.message || 'No match found yet. Please wait...');
      }
    } catch (error) {
      console.error('Error queuing for duel:', error);
      setResult('Error queuing for duel. Please try again later.');
    }
  };

  if (!gladiator) {
    return <p className="error-message">No gladiator available. Please select a gladiator.</p>;
  }

  return (
    <div className="duel-container">
      <h1>Queue for a Duel</h1>
      <form onSubmit={handleSubmit} className="duel-form">
        <div className="form-group">
          <label htmlFor="strategy">Strategy:</label>
          <select
            id="strategy"
            value={strategy}
            onChange={(e) => setStrategy(e.target.value)}
            className="form-control"
          >
            <option value="Normal">Normal</option>
            <option value="Offensive">Offensive</option>
            <option value="Defensive">Defensive</option>
            <option value="Berserk">Berserk</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="surrenderHP">Surrender HP (%):</label>
          <select
            id="surrenderHP"
            value={surrenderHP}
            onChange={(e) => setSurrenderHP(parseInt(e.target.value))}
            className="form-control"
            required
          >
            {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map((value) => (
              <option key={value} value={value}>
                {value}%
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="maxLevel">Maximum Level:</label>
          <input
            id="maxLevel"
            type="number"
            value={maxLevel}
            min={gladiator.level}
            max={gladiator.level + 5}
            onChange={(e) => setMaxLevel(parseInt(e.target.value))}
            className="form-control"
            required
          />
        </div>

        <button type="submit" className="submit-button">Queue for Duel</button>
      </form>

      {result && <p className="result-message">{result}</p>}
    </div>
  );
};

export default Duel;
