import React from 'react';

const Mail: React.FC = () => {
  return (
    <div>
      <h1>Mail</h1>
      <p>This is the Mail component.</p>
    </div>
  );
};

export default Mail;
