import React from 'react';

const Account: React.FC = () => {
  return (
    <div>
      <h1>Account</h1>
      <p>This is the Account component.</p>
    </div>
  );
};

export default Account;
