import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './contexts/AuthContext';
import { useGladiator } from './contexts/GladiatorContext';
import { useNavigate } from 'react-router-dom';

const AuthInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { setUser } = useAuth();
  const { setGladiator } = useGladiator();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get('https://njordheim.com/api/auth/user', { withCredentials: true });
        if (userResponse.data) {
          setUser(userResponse.data);
          if (userResponse.data.isDead && userResponse.data.deathBattleReport) {
            navigate(`/deathscreen/${userResponse.data.deathBattleReport}`);
          }

          const gladiatorResponse = await axios.get('https://njordheim.com/api/gladiator', { withCredentials: true });
          setGladiator(gladiatorResponse.data);
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [setUser, setGladiator, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default AuthInitializer;
