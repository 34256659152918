import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

interface GladiatorContextProps {
  gladiator: any | null;
  setGladiator: React.Dispatch<React.SetStateAction<any | null>>;
  gladiatorId: number | null;
  setGladiatorId: (id: number | null) => void;
}

const GladiatorContext = createContext<GladiatorContextProps | undefined>(undefined);

interface GladiatorProviderProps {
  children: ReactNode;
}

export const GladiatorProvider: React.FC<GladiatorProviderProps> = ({ children }) => {
  const [gladiator, setGladiator] = useState<any | null>(null);
  const [gladiatorId, setGladiatorId] = useState<number | null>(() => {
    const storedId = localStorage.getItem('gladiatorId');
    return storedId ? parseInt(storedId, 10) : null;
  });

  useEffect(() => {
    if (gladiatorId !== null) {
      localStorage.setItem('gladiatorId', gladiatorId.toString());
    } else {
      localStorage.removeItem('gladiatorId');
    }
  }, [gladiatorId]);

  return (
    <GladiatorContext.Provider value={{ gladiator, setGladiator, gladiatorId, setGladiatorId }}>
      {children}
    </GladiatorContext.Provider>
  );
};

export const useGladiator = () => {
  const context = useContext(GladiatorContext);
  if (!context) {
    throw new Error('useGladiator must be used within a GladiatorProvider');
  }
  return context;
};
